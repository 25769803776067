import Head from 'next/head'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { hotjar } from 'react-hotjar'
import {
  ThemeProvider,
  createMuiTheme,
  CssBaseline,
  Snackbar,
} from '@yosgo/swap-ui'
import { IconButton } from '@material-ui/core'
import { ParseAccountToken, RemoveAccountToken } from '../utils/HandleToken'
import FreshchatButton from '../components/Common/FreshchatButton'
import NewFeatureModal from '../components/Member/Profile/create/NewFeatureModal'
import ExpiredModal from '../components/Member/Plan/ExpiredModal'
import { GetMember } from '../utils/AppRequest'
import {
  EnableAnalytics,
  GA4_MEASUREMENT_ID,
  clearGA4EventLocalStorage,
  getGA4EventFromLocalStorage,
} from '../utils/ga4'

const GA4 = ({ enableAnalytics }) => {
  const [load, setLoad] = useState(false)

  useEffect(() => {
    ;(() => {
      //渲染畫面且 enableAnalytics 為 true 時，才執行 GA4 初始化
      if (load) {
        const debug_mode = !enableAnalytics //如果是測試環境，則 debug_mode 為 true
        if (debug_mode) console.log('> GA4 in debug_mode')
        try {
          window.dataLayer = window.dataLayer || []
          function gtag() {
            dataLayer.push(arguments)
          }
          gtag('js', new Date())
          //初始化，並加入 user_id。soho 端 id 優先
          const user_id =
            ParseAccountToken()?.id || ParseAccountToken('biz')?.id
          if (user_id) {
            gtag('config', GA4_MEASUREMENT_ID, {
              user_id,
              ...(debug_mode ? { debug_mode: true } : {}),
            })
          } else {
            gtag('config', GA4_MEASUREMENT_ID, {
              ...(debug_mode ? { debug_mode: true } : {}),
            })
          }
          //如果 local storage 有 events 資料，則傳送事件
          const eventsArray = getGA4EventFromLocalStorage()
          if (eventsArray && eventsArray.length > 0) {
            for (let i = 0; i < eventsArray.length; i++) {
              var _event = eventsArray[i]
              if (_event?.name) {
                gtag('event', _event.name, {
                  ..._event.obj,
                  send_to: GA4_MEASUREMENT_ID,
                  ...(debug_mode ? { debug_mode: true } : {}),
                })
              }
            }
            clearGA4EventLocalStorage()
          } else {
            return
          }
        } catch (err) {
          console.log(`GA4 Init error`, err)
        }
      }
    })()
  }, [load])

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`}
        onLoad={() => setLoad(true)}
      />
    </>
  )
}

const SWAPDefaultTheme = createMuiTheme({
  //Design Token
  //Color
  palette: {
    primary: {
      main: '#4862CC',
    },
  },
  primary: {
    primary50: '#E6E9F8',
    primary100: '#C1C7ED',
    primary200: '#97A3E1',
    primary300: '#6C7FD5',
    primary400: '#4862CC',
    primary500: '#1747C2',
    primary600: '#0F3FB8',
    primary700: '#0035AC',
    primary800: '#002BA1',
    primary900: '#00188B',
    primaryA11y: '#071E60',
  },
  secondary: {
    secondary50: '#FEF8E3',
    secondary100: '#FCECB7',
    secondary200: '#FBDF8A',
    secondary300: '#FAD45C',
    secondary400: '#F9C93F',
    secondary500: '#F8C131',
    secondary600: '#F7B52C',
    secondary700: '#F6A128',
    secondary800: '#F59225',
    secondary900: '#F27521',
    secondaryA11y: '#E5640C',
  },
  danger: {
    danger50: '#FFEBED',
    danger100: '#FFCCD0',
    danger200: '#F99894',
    danger300: '#F26E6A',
    danger400: '#FC4A43',
    danger500: '#FF3622',
    danger600: '#F32A23',
    danger700: '#E11C1E',
    danger800: '#D40F14',
    danger900: '#C60003',
    dangerA11y: '#A80003',
  },
  success: {
    success50: '#E6F6E8',
    success100: '#C3E8C6',
    success200: '#9BD9A1',
    success300: '#70CB7A',
    success400: '#4CC05D',
    success500: '#1EB43F',
    success600: '#10A535',
    success700: '#00932A',
    success800: '#00821E',
    success900: '#006305',
    successA11y: '#015C16',
  },
  black: {
    white: '#FFFFFF',
    black100: '#F9F9F9',
    black200: '#F6F6F6',
    black300: '#F2F2F2',
    black400: '#ECECEC',
    black500: '#CCCCCC',
    black600: '#909090',
    black700: '#6F6F6F',
    black800: '#4B4B4B',
    black900: '#2D2D2D',
    black1000: '#000000',
  },
  //  Border Radius
  borderRadius: {
    s: '4px',
    m: '8px',
    l: '12px',
    xl: '20px',
  },
  //Shadow
  boxShadow: {
    s: ' 0px 2px 4px rgba(0, 0, 0, 0.12)',
    m: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    l: '0px 6px 24px rgba(0, 0, 0, 0.1)',
    xl: '0px 8px 40px rgba(0, 0, 0, 0.12)',
  },
  // Breakpoints
  breakpoints: {
    keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xxs: 0,
      xs: 375,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  //Typography
  typography: {
    //字形
    fontFamily: [
      'MPLUSRounded1c',
      'NotoSansTC',
      'helvetica',
      'arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'NotoSansTC',
            src: 'url("https://swap-fonts.s3.ap-northeast-1.amazonaws.com/NotoSansTC-Regular.otf") format("opentype")',
            fontWeight: 400,
            fontDisplay: 'swap',
          },
          {
            fontFamily: 'NotoSansTC',
            src: 'url("https://swap-fonts.s3.ap-northeast-1.amazonaws.com/NotoSansTC-Bold.otf") format("opentype")',
            fontWeight: 700,
            fontDisplay: 'swap',
          },
          {
            fontFamily: 'NotoSansTC',
            src: 'url("https://swap-fonts.s3.ap-northeast-1.amazonaws.com/NotoSansTC-Black.otf") format("opentype")',
            fontWeight: 900,
            fontDisplay: 'swap',
          },
          {
            fontFamily: 'MPLUSRounded1c',
            src: 'url("https://swap-fonts.s3.ap-northeast-1.amazonaws.com/MPLUSRounded1c-Regular.ttf") format("truetype")',
            unicodeRange: 'U+00-024F',
            fontWeight: 400,
            fontDisplay: 'swap',
          },
          {
            fontFamily: 'MPLUSRounded1c',
            src: 'url("https://swap-fonts.s3.ap-northeast-1.amazonaws.com/MPLUSRounded1c-Bold.ttf") format("truetype")',
            unicodeRange: 'U+00-024F',
            fontWeight: 700,
            fontDisplay: 'swap',
          },
          {
            fontFamily: 'MPLUSRounded1c',
            src: 'url("https://swap-fonts.s3.ap-northeast-1.amazonaws.com/MPLUSRounded1c-Black.ttf") format("truetype")',
            unicodeRange: 'U+00-024F',
            fontWeight: 900,
            fontDisplay: 'swap',
          },
        ],
      },
    },
    MuiCard: {
      root: {
        borderRadius: '18px',
        border: '1px solid rgba(0, 0, 0, 0.13)',
      },
    },
    MuiPaper: {
      root: {
        border: '1px solid #cccccc',
      },
      rounded: {
        borderRadius: '8px',
      },
      elevation8: {
        boxShadow: ' 0px 4px 12px rgba(0, 0, 0, 0.1)',
      },
    },
    //Input
    //Menu
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 4,
        fontSize: 14,
        color: '#6f6f6f',
        '&.Mui-error': {
          color: '#D40F14',
        },
      },
    },
    // IconButton fix safari padding problem
    MuiIconButton: {
      root: {
        padding: 0,
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'unset',
      },
    },
  },
})

function MyApp({ Component, pageProps }) {
  const [enableAnalytics, setEnableAnalytics] = useState(false)
  const [
    isOpenNoticeUseExternalBrowserSnackbar,
    setIsOpenNoticeUseExternalBrowserSnackbar,
  ] = useState(false)
  const handleOpenNoticeUseExternalBrowserSnackbar = () => {
    setIsOpenNoticeUseExternalBrowserSnackbar(true)
  }
  const handleCloseNoticeUseExternalBrowserSnackbar = () => {
    setIsOpenNoticeUseExternalBrowserSnackbar(false)
  }
  // const [isOpenNewFeatureModal, setIsOpenNewFeatureModal] = useState(false)

  useEffect(() => {
    // 判斷是否為 in-app 瀏覽器
    const userAgent = navigator.userAgent
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    const isLine = userAgent.indexOf('Line') > -1
    const isFB = userAgent.indexOf('FB') > -1
    if (isMobile) {
      if ((isLine || isFB) && window.history.length === 1) {
        handleOpenNoticeUseExternalBrowserSnackbar()
      }
    }

    // 取得會員資料
    const handleGetMember = async () => {
      try {
        const res = await GetMember(`payload{
        member{
          nick_name
        }
      }`)
        if (res && res.errors === null) {
          const isProfileCreated = Boolean(res.payload.member.nick_name)
          if (!isProfileCreated) {
            // setIsOpenNewFeatureModal(true)
          }
        } else {
          throw res.errors
        }
      } catch (error) {
        console.log(error)
      }
    }

    // 判斷進入 /member/... 是否有登入
    if (window.location.pathname.search('member') !== -1) {
      const token = ParseAccountToken()
      if (!token || token.type !== 'member') {
        window.location.href = '/'
      }
      if (token && token.type && token.type === 'business') {
        RemoveAccountToken()
        window.location.href = '/'
      }
      // 判斷是否有接案履歷
      if (window.location.pathname.search('create') === -1) {
        handleGetMember()
      }
    }

    const productionSite = EnableAnalytics
    if (productionSite) {
      setEnableAnalytics(true)
    }
    if (process.env.NODE_ENV === 'production' && productionSite) {
      // HotJar
      hotjar.initialize(2037878, 6)
    }
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1, user-scalable=no"
        />
        {/**Clarity */}
        {enableAnalytics ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="//www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "3znb8btxa6");
                  `,
            }}
          />
        ) : null}
        {enableAnalytics ? (
          <link
            ref="preconnect"
            href="//js.hs-scripts.com"
            crossOrigin="true"
          />
        ) : null}
        {/**Heap Analytics */}
        {enableAnalytics ? (
          // Production
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load("3192571631");
            `,
            }}
          />
        ) : null}
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Head>
      <ThemeProvider theme={SWAPDefaultTheme}>
        <CssBaseline />
        <Component {...pageProps} />
        <Snackbar
          width="100%"
          height="100%"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transitionDirection="up"
          open={isOpenNoticeUseExternalBrowserSnackbar}
          onClose={handleCloseNoticeUseExternalBrowserSnackbar}
          message={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                為提供您最佳的網站使用體驗，建議透過預設的瀏覽器開啟本網站
              </div>
              <IconButton
                style={{ padding: 0, width: 24, height: 24, marginLeft: 8 }}
                onClick={handleCloseNoticeUseExternalBrowserSnackbar}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="white"
                  />
                </svg>
              </IconButton>
            </div>
          }
        />
        {/* <NewFeatureModal isOpen={isOpenNewFeatureModal} /> */}
        <ExpiredModal />
        <FreshchatButton />
        {/**SWAP_GA4 */}
        <GA4 enableAnalytics={enableAnalytics} />
      </ThemeProvider>
    </>
  )
}

export default MyApp
