import { useMediaQuery } from '@material-ui/core'
import { SWAPSpace, Typography } from '@yosgo/swap-ui'

const mediaPath = 'https://swap-img.swap.work/media/profile'

const WelcomeRight = () => {
  const match1280 = useMediaQuery('(min-width:1280px)')
  return (
    <div
      style={{
        backgroundColor: '#F6F6F6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zoom: !match1280 ? 0.8 : 1,
        }}
      >
        <SearchBar />
        <SWAPSpace size={20} />
        <Card />
        <SWAPSpace size={84} />
        <div
          style={{
            width: 585,
            height: 308,
            padding: '35px 41px',
            position: 'relative',
          }}
        >
          <Photo />
          <ThreeBlocks />
        </div>
      </div>
    </div>
  )
}

export const SearchBar = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: 42,
        width: 360,
        border: '1px solid #e0e0e0',
        borderRadius: '418px',
        boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        padding: 12,
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon_seacrh}
        <img
          src={`${mediaPath}/searchbarword.svg`}
          style={{ marginLeft: 28 }}
        />
      </div>
      {icon_close}
    </div>
  )
}

interface CardProps {
  noLine?: boolean
}

export const Card = ({ noLine }: CardProps) => {
  return (
    <div
      style={{
        width: 359,
        height: 162,
        backgroundColor: '#ffffff',
        padding: '8px 16px',
        borderRadius: 9,
        boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.12)',
        position: 'relative',
      }}
    >
      <img src={`${mediaPath}/searchresult.png`} height={'100%'} />
      {!noLine && (
        <img
          src={`${mediaPath}/line.svg`}
          height={96}
          style={{ position: 'absolute', bottom: -85, left: 167 }}
        />
      )}
    </div>
  )
}

const Photo = () => {
  return (
    <div style={{ position: 'relative' }}>
      <img src={`${mediaPath}/girlphoto.png`} height={238} />
      <Tooltip />
      <img
        src={`${mediaPath}/verifiedicon.svg`}
        height={32}
        style={{ position: 'absolute', left: 18, top: 23 }}
      />
    </div>
  )
}

const Tooltip = () => {
  return (
    <div style={{ width: 160, position: 'absolute', left: -48, top: -28 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 12,
          fontWeight: 700,
          color: '#ffffff',
          backgroundColor: '#2D2D2D',
          borderRadius: 4,
          height: 32,
        }}
      >
        已通過 SWAP 實名認證！
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <svg
          width="14"
          height="6"
          viewBox="0 0 14 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.59108 5.445C7.21517 5.76436 6.66255 5.76193 6.28947 5.43928L0.000106382 -2.70235e-07L14 9.53674e-07L7.59108 5.445Z"
            fill="#2D2D2D"
          />
        </svg>
      </div>
    </div>
  )
}

const ThreeBlocks = () => {
  const array = [
    { iconPath: undefined, text: undefined },
    { iconPath: `${mediaPath}/icon1.png`, text: '個人作品集網站' },
    { iconPath: `${mediaPath}/icon2.png`, text: '設計師個人成長文章' },
  ]

  return (
    <div style={{ position: 'absolute', zIndex: 1, right: 0, top: 0 }}>
      {array.map((i, index) => (
        <div
          key={index}
          style={{
            width: 320,
            height: 92,
            backgroundColor: '#ffffff',
            padding: 16,
            borderRadius: 24,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: index !== 1 ? 32 : 0,
            marginLeft: index === 1 ? 32 : 0,
            marginTop: index === 1 ? 16 : 0,
            marginBottom: index === 1 ? 16 : 0,
          }}
        >
          {i.iconPath ? (
            <img src={i.iconPath} height={60} />
          ) : (
            <div
              style={{
                width: 60,
                height: 60,
                borderRadius: 16,
                backgroundColor: '#ececec',
              }}
            />
          )}
          {i.text ? (
            <Typography variant="body1">{i.text}</Typography>
          ) : (
            <div
              style={{
                width: 148,
                height: 16,
                borderRadius: 4,
                backgroundColor: '#ececec',
              }}
            />
          )}
          <div
            style={{
              width: 48,
              height: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon_threedots}
          </div>
        </div>
      ))}
    </div>
  )
}

const icon_seacrh = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.63866 12.6357C7.9485 12.6357 9.17154 12.2096 10.1658 11.4995L13.9059 15.2396C14.0795 15.4132 14.3083 15.5 14.545 15.5C15.0579 15.5 15.4288 15.1055 15.4288 14.6005C15.4288 14.3638 15.3499 14.1428 15.1763 13.9692L11.4598 10.2449C12.241 9.2191 12.7065 7.94871 12.7065 6.56786C12.7065 3.23014 9.97638 0.5 6.63866 0.5C3.29305 0.5 0.570801 3.23014 0.570801 6.56786C0.570801 9.90558 3.29305 12.6357 6.63866 12.6357ZM6.63866 11.3259C4.02688 11.3259 1.88064 9.17175 1.88064 6.56786C1.88064 3.96397 4.02688 1.80984 6.63866 1.80984C9.24255 1.80984 11.3967 3.96397 11.3967 6.56786C11.3967 9.17175 9.24255 11.3259 6.63866 11.3259Z"
      fill="#8E8E93"
    />
  </svg>
)

const icon_close = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.52017 7.06303L14.2023 12.7071L12.7929 14.1261L7.10117 8.47251L1.40945 14.1261L0 12.7071L5.68218 7.06303L0 1.41896L1.40945 0L7.10117 5.65355L12.7929 0L14.2023 1.41896L8.52017 7.06303Z"
      fill="#828282"
    />
  </svg>
)

const icon_threedots = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 12C16 11.4696 16.2107 10.9609 16.5858 10.5858C16.9609 10.2107 17.4696 10 18 10C18.5304 10 19.0391 10.2107 19.4142 10.5858C19.7893 10.9609 20 11.4696 20 12C20 12.5304 19.7893 13.0391 19.4142 13.4142C19.0391 13.7893 18.5304 14 18 14C17.4696 14 16.9609 13.7893 16.5858 13.4142C16.2107 13.0391 16 12.5304 16 12ZM10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12ZM4 12C4 11.4696 4.21071 10.9609 4.58579 10.5858C4.96086 10.2107 5.46957 10 6 10C6.53043 10 7.03914 10.2107 7.41421 10.5858C7.78929 10.9609 8 11.4696 8 12C8 12.5304 7.78929 13.0391 7.41421 13.4142C7.03914 13.7893 6.53043 14 6 14C5.46957 14 4.96086 13.7893 4.58579 13.4142C4.21071 13.0391 4 12.5304 4 12V12Z"
      fill="#6F6F6F"
    />
  </svg>
)

export default WelcomeRight
