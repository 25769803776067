declare const API_URL: string

import axios from 'axios'
import { captureException } from '@sentry/nextjs'
import { ReadAccountToken } from './HandleToken'
import { IsBeta, WindowExist } from '../utils/HandleMode'
import { addGA4EventToLocalStorage, GA4_REGISTER_EVENT_NAME } from './ga4'
import jwt from 'jsonwebtoken'
import { PathOr } from './FunctionsBox'
import { HeapUserIdentify } from './Heap'

const CheckAPIURL = () => {
  if (!API_URL) throw 'API_URL 不存在'
}
const CheckAccountToken = (type?: string) => {
  if (type === 'biz') {
    if (!ReadAccountToken('biz')) throw '請重新登錄'
  }
  if (type === 'member') {
    if (!ReadAccountToken('member')) throw '請重新登錄'
  }
  if (!type) {
    if (!ReadAccountToken()) throw '請重新登錄'
  }
}
const captureExceptionFromError = (err: any) => {
  const isStaging =
    window.location.origin.indexOf('staging.swap.work') >= 0 ||
    window.location.origin.indexOf('localhost') >= 0
  if (isStaging) return
  captureException(err)
}

/**
 * 對象: 會員
 * 動作: 註冊
 */
export const RegisterMember = async (inputData: {
  email: string
  password: string
  oauth_origin: string
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
            mutation($email: String! $password: String! $oauth_origin:String!) {
              registerMember(inputData:{
                email: $email
                password: $password
                oauth_origin: $oauth_origin
              }) {
                payload {
                  token
                  member {
                    email
                    picture
                  }
                }
                message
                status
                errors
              }
            }
            `,
        variables: {
          email: inputData.email,
          password: inputData.password,
          oauth_origin: inputData.oauth_origin,
        },
      })
      .then((r) => {
        const value = r.data.data.registerMember
        if (value.message === '重複註冊') {
          result = value
        } else {
          try {
            const token = jwt.decode(value.payload.token)
            const id = PathOr(undefined, ['id'], token)
            //GA4 Event
            addGA4EventToLocalStorage({
              name: GA4_REGISTER_EVENT_NAME,
              obj: {
                swap_member_id: id,
              },
            })
            //HEAP
            HeapUserIdentify(id)
          } catch {}
          result = value
        }
      })
      .catch((err) => {
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of RegisterMember', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 註冊
 * @param inputData
 */
export const RegisterBusiness = async (inputData: {
  email: string
  password: string
  company_name: string
  tax_id: string
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          mutation($email: String! $password: String! $company_name: String! $tax_id: String!) {
            registerBusiness(inputData: {
              email: $email
              password: $password
              company_name: $company_name
              tax_id: $tax_id
            }) {
              payload {
                token
                business {
                  id
                  email
                  name
                }
              }
              message
              status
              errors
            }
          }
        `,
        variables: {
          email: inputData.email,
          password: inputData.password,
          company_name: inputData.company_name,
          tax_id: inputData.tax_id,
        },
      })
      .then((r) => (result = r.data.data.registerBusiness))
      .catch((err) => {
        captureException(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of RegisterBusiness', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 登錄
 */
export const Login = async (inputData: {
  email: string
  password: string
  type: string
  oauth_origin: string
}) => {
  let result
  const { email, password, type, oauth_origin } = inputData
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          query($email: String! $password: String! $type: String! $oauth_origin:String!) {
            login(inputData: {
              email: $email
              password: $password
              type: $type
              oauth_origin:$oauth_origin
            }) {
              payload {
                token
              }
              status
              message
              errors
            }
          }
        `,
        variables: {
          email,
          password,
          type,
          oauth_origin,
        },
      })
      .then((r) => {
        const value = r.data.data.login
        try {
          const token = jwt.decode(value.payload.token)
          const id = PathOr(undefined, ['id'], token)
          if (id) {
            HeapUserIdentify(id)
          }
        } catch {}
        result = value
      })
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of Login', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 更新會員相關資料
 * 備註: 需要有會員的 token 字串放在 header 中
 */
export const UpdateMember = async (inputData: {
  gender?: number
  first_name?: string
  last_name?: string
  birth?: string
  picture?: string
  tags?: string
  bio?: string
  description?: string
  facebook_insights?: string
}) => {
  let result
  const {
    gender,
    first_name,
    last_name,
    birth,
    picture,
    tags,
    bio,
    description,
    facebook_insights,
  } = inputData
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
      mutation(
        $gender: Int
        $first_name: String
        $last_name: String
        $birth: String
        $picture: String
        $tags: String
        $bio: String
        $description: String
        $facebook_insights: String
      ) {
        updateMember(inputData: {
          gender: $gender,
          first_name: $first_name,
          last_name: $last_name,
          birth: $birth,
          picture: $picture,
          tags: $tags,
          bio: $bio,
          description: $description
          facebook_insights:  $facebook_insights
        }) {
          payload {
            email
            first_name
            picture
          },
          status 
          message
          errors
        }
      }
      `,
          variables: {
            gender,
            first_name,
            last_name,
            birth,
            picture,
            tags,
            bio,
            description,
            facebook_insights,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateMember))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateMember', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 更新品牌相關資料
 * 備註: 需要有品牌的 token 字串放在 header 中
 */
export const UpdateBusiness = async (inputData: {
  name?: string
  bio?: string
  picture?: string
  tags?: string
  description?: string
  facebook_insights?: string
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: UpdateBusinessInputData!) {
            updateBusiness(inputData: $inputData) {
              payload {
                id
                email
                name
                picture
                bio
              }
              message
              status
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateBusiness))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateBusiness', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 更新會員的合作方式資料
 * 備註: 需要有會員的 token 字串放在 header 中
 */
export const UpdateIntentions = async (
  inputData: Array<{
    id?: string
    title: string
    description: string
  }>
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: [UpdateIntentionInputData]!) {
            updateIntentions(inputData: $inputData) {
              payload {
                id
                title
                description
                created_at
                updated_at
                followers {
                  picture
                  first_name
                  last_name
                  email
                }
              }
              status
              message
              errors
            }
          }
        `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateIntentions))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateIntentions', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 移除合作方式
 * 備註: 需要有會員的 token 字串放在 header 中
 * @inputData
 * 合作方式之 ID
 */
export const DeleteIntention = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: String!) {
          deleteIntention(inputData: $inputData) {
            status
            message
            errors
          }
        }
        `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.deleteIntention))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteIntention', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 建立該會員的商業合作意圖，用於會員 onboarding 時
 * 備註: 需要有會員的 token 字串放在 header 中
 */
export const CreateIntentions = async (
  inputData: Array<{
    title: string
    description: string
  }>
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation($inputData: [CreateIntentionInputData]!) {
              createIntentions(inputData: $inputData) {
                payload {
                  id
                  title
                  description
                  created_at
                  updated_at
                }
                status
                message
                errors
              }
            }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.createIntentions))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateIntentions', err)
  }
  return result
}

/**
 * 對象: 會員以及被收藏的會員
 * 動作: 會員可以收藏某個會員的合作方式
 * 備註: 需要有會員的 token 字串放在 header 中
 * @param inputData
 * 被收藏之合作方式之 id
 */
export const CreateIntentionFollower = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: String!) {
            createIntentionFollower(inputData: $inputData) {
              payload {
                intention_id
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.createIntentionFollower))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateIntentionFollower', err)
  }
  return result
}

/**
 * 對象: 會員以及被收藏的會員
 * 動作: 取消收藏某個合作方式
 * 備註: 需要有會員的 token 字串放在 header 中
 * @param inputData
 * 取消收藏之合作方式之 id
 */
export const DeleteIntentionFollower = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: String!) {
          deleteIntentionFollower(inputData: $inputData) {
            payload {
              intention_id
            }
            status
            message
            errors
          }
        }
        `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.deleteIntentionFollower))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteIntentionFollower', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 建立該會員的作品集，用於會員 onboarding 時
 * 備註: 需要有會員的 token 字串放在 header 中
 */
export const CreatePortfolios = async (
  inputData: Array<{
    title: string
    description: string
    picture: string
    link: string
  }>
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: [CreatePortfolioInputData]!) {
            createPortfolios(inputData: $inputData) {
              payload {
                id
                title
                description
                picture
                link
                created_at
                updated_at
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.createPortfolios))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreatePortfolios', err)
  }
  return result
}

/**
 * 對象: 會員們
 * 動作: 會員可以肯定某個會員的作品集
 * 備註: 需要有會員的 token 字串放在 header 中
 * @param inputData
 * 被肯定之作品之 id
 */
export const CreatePortfolioConfirm = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: String!) {
          createPortfolioConfirm(inputData: $inputData) {
            payload {
              portfolio_id
            }
            status
            message
            errors
          }
        }
        `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.createPortfolioConfirm))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreatePortfolioConfirm', err)
  }
  return result
}

/**
 * 對象: 會員們
 * 動作: 取消肯定某個作品集
 * 備註: 需要有會員的 token 字串放在 header 中
 * @param inputData
 * 作品集之 id
 */
export const DeletePortfolioConfirm = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: String!) {
            deletePortfolioConfirm(inputData: $inputData) {
              payload {
                portfolio_id
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.deletePortfolioConfirm))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeletePortfolioConfirm', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 更新會員的作品集資料
 * 備註: 需要有會員的 token 字串放在 header 中
 * @inputData
 *
 */
export const UpdatePortfolios = async (
  inputData: Array<{
    id?: string
    title: string
    description: string
    picture: string
    link: string
  }>
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation($inputData: [UpdatePortfolioInputData]!) {
              updatePortfolios(inputData: $inputData) {
                payload {
                  id
                }
                status 
                message
                errors
              }
            }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updatePortfolios))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdatePortfolios', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 移除作品集
 * 備註: 需要有會員的 token 字串放在 header 中
 * @inputData
 * 作品之 ID
 */
export const DeletePortfolio = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: String!) {
          deletePortfolio(inputData: $inputData) {
            status
            message
            errors
          }
        }
        `,
          variables: { inputData },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.deletePortfolio))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 查看會員資料以及關聯資料(基本資料、合作方式... etc)
 * 備註: 會員自行使用。需要有會員的 token 字串放在 header 中
 */
const GetMemberDefaultField = `
payload {
  token
  member {
    identity
    id
    is_foreigner
    email
    first_name
    last_name
    birth
    tags
    bio
    description
    picture
    gender
    facebook_insights
    facebook_insights_updated_at
    is_valid_wallet
    is_confirm_wallet
    verified_name
    verified_phone
    verified_bank_code
    verified_bank_id
    verified_bank_name
    verified_id_number
    verified_address
    verified_comment
    verified_images
    verified_state
    created_at
    subscribe_expired_at
    cover_picture
    nick_name
    personal_id
    verified_reject_reson {
      verified_name {
        name
        title
        reject
        reson
      }
      verified_id_number {
        name
        title
        reject
        reson
      }
      verified_phone {
        name
        title
        reject
        reson
      }
      verified_bank_code {
        name
        title
        reject
        reson
      }
      verified_bank_id {
        name
        title
        reject
        reson
      }
      verified_address {
        name
        title
        reject
        reson
      }
      verified_picture {
        name
        title
        reject
        reson
      }
      verified_picture_front {
        name
        title
        reject
        reson
      }
      verified_picture_back {
        name
        title
        reject
        reson
      }
    }
    wallet {
      balance
      withdraw_balance
      is_ban
      wallet_logs {
        id
        user_id
        body
        action
        status
        receive_user_id
        request_entity_id
        user_type
        created_at
        updated_at
        labor_report
      }
      created_at
      updated_at
    }
    social_medias {
      id
      link
      title
      fans_count
      type
      created_at
      updated_at
    }
    milestones {
      id
      description
      sort
      completed_at
      created_at
      updated_at
    }
    intentions {
      id
      title
      description
      updated_at
      created_at
      followers {
        id
        first_name
        last_name
        picture
        email
      }
      business_followers {
        id
        email
        name
        picture
      }
    }
    portfolios {
      id
      title
      description
      picture
      link
      updated_at
      created_at
      confirms {
        id
        first_name
        last_name
        picture
        email
      }
      business_confirms {
        id
        name
        picture
        email
      }
    }
    features_permissions {
      name
      status
      config{
        quota
      }
      free_quota
    }
  }
}
`
export const GetMember = async (queryField?: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
            query {
              getMember {
                ${queryField || GetMemberDefaultField}
                status
                message
                errors
              }
            }
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.getMember))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMember', err)
  }
  return result
}

export const GetMemberBills = async (inputData: {
  offset: number
  limit: number
  orderBy: {
    field: string
    sort: string
  }
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
          query($offset: Int $limit: Int $orderBy: OrderBy) {
            getMemberBills(offset: $offset limit: $limit orderBy:$orderBy) {
              pagination {
                count
                offset 
                limit
              }
              payload {
                member_payment_request {
                  id 
                  access_code
                }
                bill_type
                invoice_info
                labor_report 
                fee 
                created_at
              }
                 message
                 status
                 errors
             }
           }
        `,
          variables: {
            offset: inputData.offset,
            limit: inputData.limit,
            orderBy: inputData.orderBy,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.getMemberBills))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMemberBills', err)
  }
  return result
}

export const MemberSubscribe = async (inputData: {
  tappay_prime: string
  credit_card_name: string
  credit_card_phone_number: string
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: MemberSubscribeInputData) {
            memberSubscribe(inputData: $inputData) {
              status
              message
              errors
            }
          }
      `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.memberSubscribe))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of MemberSubscribe', err)
  }
  return result
}

export const UpdateMemberIsOnboarded = async () => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation {
              updateMemberIsOnboarded {
                message
                errors
                status
                }
              }
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateMemberIsOnboarded))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateMemberIsOnboarded', err)
  }
  return result
}

export const MemberCancelSubscribe = async () => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation {
            memberCancelSubscribe {
              status
              message
              errors
            }
          }
      `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.memberCancelSubscribe))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of MemberCancelSubscribe', err)
  }
  return result
}

export const ExchangeDiscountEventCode = async (inputData: {
  code: string
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: ExchangeDiscountEventCodeInputData) {
            exchangeDiscountEventCode(inputData: $inputData) {
              payload {
               discount_event {
                 title
                }
                payment_amount
                charge_fee
                expired_at
                count
              }
              status
              message
              errors
            }
          }
      `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.exchangeDiscountEventCode))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of ExchangeDiscountEventCode', err)
  }
  return result
}

export const GetMemberDiscountCoupons = async () => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
        query {
            getMemberDiscountCoupons{
              payload{
                discount_event{
                  title
                }
                expired_at
                charge_fee
                payment_amount
                code
              }
              status
              message
              errors
            }
          }
      `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.getMemberDiscountCoupons))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMemberDiscountCoupons', err)
  }
  return result
}

/**
 * 對象: 任一網站訪客
 * 動作: 查看會員的公開個人頁面
 * 備註: 不需 token 只需會員的 ID
 */
export const GetProfile = async (inputData: { memberId: string }) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          query($memberId: String!) {
            getProfile(inputData: $memberId) {
              payload {
                member {
                  id
                  email
                  first_name
                  last_name
                  verified_name
                  birth
                  tags
                  bio
                  description
                  picture
                  facebook_insights
                  facebook_insights_updated_at
                  social_medias {
                    id
                    link
                    title
                    fans_count
                    type
                    created_at
                    updated_at
                  }
                  milestones {
                    id
                    description
                    sort
                    completed_at
                    created_at
                    updated_at
                  }
                  intentions {
                    id
                    title
                    description
                    updated_at
                    created_at
                    followers {
                      id
                      first_name
                      last_name
                      picture
                      email
                    }
                    business_followers {
                      id
                      email
                      name
                      picture
                    }
                  }
                  portfolios {
                    id
                    title
                    description
                    picture
                    link
                    updated_at
                    created_at
                    confirms {
                      id
                      first_name
                      last_name
                      picture
                      email
                    }
                    business_confirms {
                      id
                      name
                      picture
                      email
                    }
                  }
                }
              }
              status
              message
              errors
            }
          }
      `,
        variables: {
          memberId: inputData.memberId,
        },
      })
      .then((r) => (result = r.data.data.getProfile))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetProfile', err)
  }
  return result
}

export const GetProfileLight = async (inputData: { memberId: string }) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          query($memberId: String!) {
            getProfile(inputData: $memberId) {
              payload {
                member {
                  id
                  email
                  first_name
                  last_name
                  picture
                }
              }
              status
              message
              errors
            }
          }
      `,
        variables: {
          memberId: inputData.memberId,
        },
      })
      .then((r) => (result = r.data.data.getProfile))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetProfile', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 品牌首頁顯示的資料
 * 備註: 品牌自行使用。需要有品牌的 token 字串放在 header 中
 */

const GetBusinessDefaultField = `
              payload {
                token
                business {
                  id
                  email
                  name
                  bio
                  picture
                  description
                  tags
                  facebook_insights
                  facebook_insights_updated_at
                  verified_company_name
                  verified_tax_id
                  verified_address
                  verified_pricipal_name
                  verified_pricipal_phone
                  verified_pricipal_email
                  verified_bank_code
                  verified_bank_id
                  verified_comment
                  verified_images
                  identity
                  is_confirm_wallet
                  is_ban
                  contract_quota
                  contract_report         
                  contract_comment
                  contract_from_at
                  contract_to_at                         
                  wallet {
                    balance
                    is_ban
                    wallet_logs {
                      id
                      user_id
                      body
                      action
                      status
                      receive_user_id
                      request_entity_id
                      user_id
                      user_type
                      created_at
                      updated_at
                    }
                    created_at
                    updated_at
                  }
                  albums {
                    id
                    name
                    description
                    created_at
                    sort
                    images {
                      uri
                      sort
                    }
                  }
                  intentions {
                    id
                    title
                    description
                    picture
                    created_at
                    updated_at
                    followers {
                      id
                      first_name
                      last_name
                      picture
                      email
                    }
                    business_followers {
                      id
                      email
                      name
                      picture
                    }
                  }
                  can_buy_quota
                  created_at
                  updated_at
                }
              }
`

export const GetBusiness = async (queryField?: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
          query {
            getBusiness{
               ${queryField || GetBusinessDefaultField}
              message
              status
              errors
            }
          }          
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.getBusiness))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetBusiness', err)
  }
  return result
}

/**
 * 對象: 任一網站訪客
 * 動作: 查看品牌的公開頁面
 * 備註: 不需 token 只需品牌的 ID
 */
export const GetBusinessProfile = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          query($inputData: String!) {
            getBusinessProfile(inputData: $inputData) {
              payload {
                business {
                  id
                  email
                  name
                  bio
                  picture
                  description
                  tags
                  facebook_insights
                  facebook_insights_updated_at
                  albums {
                    id
                    name
                    description
                    created_at
                    sort
                    images {
                      uri
                      sort
                    }
                  }
                  intentions {
                    id
                    title
                    description
                    picture
                    created_at
                    updated_at
                    followers {
                      id
                      first_name
                      last_name
                      picture
                      email
                    }
                    business_followers {
                      id
                      email
                      name
                      picture
                    }
                  }
                  created_at
                  updated_at
                }
              }
              message
              status
              errors
            }
          }
        `,
        variables: {
          inputData,
        },
      })
      .then((r) => (result = r.data.data.getBusinessProfile))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetBusinessProfile', err)
  }
  return result
}

export const GetBusinessProfileLight = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          query($inputData: String!) {
            getBusinessProfile(inputData: $inputData) {
              payload {
                business {
                  id
                  email
                  name
                  picture
                  bio
                  verified_tax_id
                  verified_company_name
                }
              }
              message
              status
              errors
            }
          }
        `,
        variables: {
          inputData,
        },
      })
      .then((r) => (result = r.data.data.getBusinessProfile))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetBusinessProfile', err)
  }
  return result
}

/**
 * 對象: 會員或商家
 * 動作: 推薦的會員名單
 * 備註: 需要 token
 */
export const GetRecommentMembers = async (offset: number, limit: number) => {
  let result
  try {
    if (!IsBeta()) return
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
        query($offset: Int $limit: Int) {
          getRecommentMembers(offset: $offset limit: $limit) {
            payload {
              members {
                id
                email
                first_name
                last_name
                birth
                tags
                bio
                description
                picture
                gender
                portfolios {
                  id
                  title
                  picture
                  description
                  link
                  confirms {
                    first_name
                    last_name
                    email
                    picture
                  }
                }
                intentions {
                  id
                  title
                  description
                  followers {
                    first_name
                    last_name
                    email
                    picture
                  }
                }
              }
            }
            status
            message
            errors
          }
        }
        `,
        variables: {
          offset,
          limit,
        },
      })
      .then((r) => (result = r.data.data.getRecommentMembers))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetRecommentMembers', err)
  }
  return result
}

/**
 * 對象: 會員或商家
 * 動作: 推薦的會員名單
 * 備註: 需要 token
 * @param offset
 * @param limit
 */
export const GetRecommentBusinesses = async (offset: number, limit: number) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          query($offset: Int $limit: Int) {
            getRecommentBusinesses(offset: $offset limit: $limit) {
              payload {
                businesses {
                  id
                  email
                  name
                  bio
                  picture
                  description
                  tags
                  intentions {
                    id
                    title
                    description
                    picture
                    created_at
                    updated_at
                    followers {
                      id
                      first_name
                      last_name
                      picture
                      email
                    }
                    business_followers {
                      id
                      email
                      name
                      picture
                    }
                  }
                  created_at
                  updated_at
                }
              }
              message
              status
              errors
            }
          }
        `,
          variables: {
            offset,
            limit,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.getRecommentBusinesses))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetRecommentBusinesses', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 於個人頁面中更新自己的社群平台
 * 備註: 需要 token，一次更新一項網站
 */
export const UpdateSocialMedia = async (inputData: {
  id?: number
  link?: string
  type: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation($id: Int $type: Int $link: String) {
              updateSocialMedia(inputData: {
                id: $id
                type: $type
                link: $link
              }) {
                payload {
                  id
                  link
                  title
                  fans_count
                  type
                  created_at
                  updated_at
                }
                status
                message
                errors
              }
            }
          `,
          variables: {
            id: inputData.id,
            type: inputData.type,
            link: inputData.link,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateSocialMedia))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateSocialMedia', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 於個人頁面中更新自己的里程碑
 * 備註: 需要 token
 */
export const UpdateMilestones = async (
  inputData: Array<{
    id?: number
    description: string
    sort: number
    completed_at: string
  }>
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: [MilestoneInputData]!) {
            updateMilestones(inputData: $inputData) {
              payload {
                id
                description
                sort
                completed_at
                created_at
                updated_at
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateMilestones))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateMilestones', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 於個人頁面中刪除某個里程碑
 * 備註: 需要 token
 */
export const DeleteMilestone = async (inputData: number) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: Int) {
          deleteMilestone(inputData: $inputData) {
            status
            message
            errors
          }
        }
      `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.deleteMilestone))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteMilestone', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 更新品牌的合作方式
 * 備註: 需要 token
 */
export const UpdateBusinessIntentions = async (
  inputData: Array<{
    id?: string
    title: string
    description: string
  }>
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation($inputData: [UpdateBusinessIntentionInputData!]) {
              updateBusinessIntentions(inputData: $inputData){
                payload {
                  id
                  title
                  description
                  picture
                  created_at
                  updated_at
                }
                status
                message
                errors
              }
            }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateBusinessIntentions))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateBusinessIntentions', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 移除合作方式
 * 備註: 需要有品牌的 token 字串放在 header 中
 * @inputData
 * 合作方式之 ID
 */
export const DeleteBusinessIntention = async (inputData: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: String!) {
          deleteBusinessIntention(inputData: $inputData) {
            status
            message
            errors
          }
        }
        `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.deleteBusinessIntention))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteBusinessIntention', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 請求重新設定密碼
 * 備註: 輸入信箱獲得重置密碼的信件件
 */
export const RequestResetPassword = async (inputData: {
  email: string
  type: 'member' | 'business'
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
        mutation($inputData: RequestResetPasswordInputData) {
          requestResetPassword(inputData: $inputData) {
            status
            message
            errors
          }
        }
        `,
        variables: {
          inputData,
        },
      })
      .then((r) => (result = r.data.data.requestResetPassword))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of RequestResetPassword')
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 重置密碼
 */
export const ResetPassword = async (inputData: {
  token: string
  password: string
  type: string
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
        mutation($inputData: ResetPasswordInputData) {
          resetPassword(inputData: $inputData) {
            status
            message
            errors
          }
        }
        `,
        variables: {
          inputData,
        },
      })
      .then((r) => (result = r.data.data.resetPassword))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of ResetPassword', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 藉由 YOSGO 信箱發送訊息
 */
export const EmailSender = async (inputData: {
  to: string
  subject: string
  text: string
}) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        'https://nfkzfh8t2a.execute-api.ap-southeast-1.amazonaws.com/v1/graphql',
        {
          query: `
          mutation($to: String! $subject: String! $text: String!) {
            email_sender(to: $to, subject: $subject, text: $text, source:"swap@yosgo.com") {
              msg
              success
              ID
            }
          }
          `,
          variables: {
            to: inputData.to,
            subject: inputData.subject,
            text: inputData.text,
          },
        }
      )
      .then((r) => (result = r.data.data.email_sender))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of EmailSender', err)
  }
  return result
}

/**
 * 對象: 管理員
 * 動作: 查看所有的 member 名單
 */
export const GetMembers = async () => {
  let result
  try {
    await axios
      .post(API_URL, {
        query: `
          query {
            getMembers {
              payload {
                id
                email
                first_name
                last_name
                birth
                tags
                bio
                description
                picture
                gender
                created_at
                intentions {
                  id
                  title
                }
                portfolios {
                  id
                  title
                }
                social_medias {
                  id
                  link
                  title
                }
                milestones {
                  id
                  description
                }
              }
              status
              message
              errors
            }
          }
        `,
      })
      .then((r) => (result = r.data.data.getMembers))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMembers', err)
  }
  return result
}

/**
 * 對象: 管理員
 * 動作: 查看所有的 business 名單
 */
export const GetBusinesses = async () => {
  let result
  try {
    await axios
      .post(API_URL, {
        query: `
          query {
            getBusinesses {
              payload {
                id
                email
                name
                bio
                picture
                description
                tags
                albums {
                  id
                }
                intentions {
                  id
                  title
                  description
                  picture
                }
                created_at
                updated_at
              }
              message
              status
              errors
            }
          }
        `,
      })
      .then((r) => (result = r.data.data.getBusinesses))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetBusinesses', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 建立新的相簿
 */
export const CreateAlbum = async (inputData: {
  name: string
  description: string
  sort: number
}) => {
  const { name, description, sort } = inputData
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($name: String! $description: String! $sort: Int! ) {
          createAlbum(inputData: {
            name: $name 
            description: $description 
            sort: $sort
          }) {
            payload {
              id
              name
              description
              created_at
              sort
            }
            message
            status
            errors
          }
        }
        `,
          variables: {
            name,
            description,
            sort,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.createAlbum))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateAlbum', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 更新某個相簿
 */
export const UpdateAlbum = async (inputData: {
  id: string
  name: string
  description: string
  sort: number
  images: Array<{ uri: string; sort: number }>
}) => {
  const { id, name, description, sort, images } = inputData
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation(
          $id: String 
          $name: String 
          $description: String 
          $sort: Int 
          $images: [UpdateAlbumImageInputData] 
        ) {
          updateAlbum(inputData: {
            id: $id
            name: $name 
            description: $description 
            sort: $sort
            images: $images
          }) {
            payload {
              id
            }
            message
            status
            errors
          }
        }
        `,
          variables: {
            id,
            name,
            description,
            sort,
            images,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateAlbum))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateAlbum', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 移除某個相簿
 */
export const DeleteAlbum = async (inputData: string) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: String!) {
            deleteAlbum(inputData: $inputData) {
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.deleteAlbum))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteAlbum', err)
  }
  return result
}

/**
 * 對象: 會員、品牌
 * 動作: 查看所有訊息記錄
 */
export const GetMessages = async () => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          query {
            getMessages {
              payload {
                send_messages {
                  id
                  body
                  created_at
                  sender {
                    id
                    email
                    picture
                    name
                    type
                  }
                  receiver {
                    id
                    email
                    picture
                    name
                    type
                  }
                }
                receive_messages {
                  id
                  body
                  created_at
                  sender {
                    id
                    email
                    picture
                    name
                    type
                  }
                  receiver {
                    id
                    email
                    picture
                    name
                    type
                  }
                }
              }
              errors
              message
            }
          }
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.getMessages))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMessages', err)
  }
  return result
}

/**
 * 對象: 會員、品牌
 * 動作: 傳送訊息
 */
export const CreateMessage = async (inputData: {
  body: string
  receiver_id: string
  receiver_type: string
}) => {
  const { body, receiver_id, receiver_type } = inputData
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($body: String $receiver_id: String $receiver_type: String  ) {
            createMessage(inputData: {
              body: $body,
              receiver_id: $receiver_id,
              receiver_type: $receiver_type
            }) {
              message
              errors 
            }
          }
          `,
          variables: { body, receiver_id, receiver_type },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.createMessage))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateMessage', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 建立錢包
 * 備註：帶使用者的 Token 於 header 中
 */
export const CreateWallet = async () => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation {
              createWallet {
                payload {
                  id
                }
                status
                errors
                message
              }
            }          
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.createWallet))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateWallet', err)
  }
  return result
}

/**
 * 對象: 會員、品牌
 * 動作: 建立錢包
 * 備註：帶使用者的 Token 於 header 中
 */
export const CreateBusinessWallet = async () => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation {
              createWallet {
                payload {
                  id
                }
                status
                errors
                message
              }
            }          
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.createWallet))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    console.log('Error of CreateBusinessWallet', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 發送儲值的通知
 * 備註：帶使用者的 Token 於 header 中
 */
export const RequestCharge = async (inputData: { value: number }) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
            mutation {
              requestCharge(
                inputData: {
                  value: ${inputData.value},
                  check_num: 0
                }
              ) {
                status
                message
                errors
              }
            }
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.requestCharge))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of RequestCharge', err)
  }
  return result
}

export const GetMemberWithdrawDetails = async (inputData: {
  value: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          query($inputData:GetMemberWithdrawDetailsInputData! ){
            getMemberWithdrawDetails(inputData: $inputData) {
            payload {
              value
              withdraw_value
              income_tax
              second_generation_tax
              labor_project_domain
              case {
                description
              }
            }
            message
            errors
            status
          }
        }
      `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )

      .then((r) => (result = r.data.data.getMemberWithdrawDetails))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMemberWithdrawDetails', err)
  }
  return result
}

/**
 * 對象: 會員
 * 動作: 發送提領通知
 * 備註：帶使用者的 Token 於 header 中
 */
export const RequestWithdraw = async (inputData: {
  value: number
  labor_project_code: string
  labor_project_domain: string
}) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: RequestWithdrawInputData) {
            requestWithdraw(inputData: $inputData) {
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.requestWithdraw))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of RequestWithdraw', err)
  }
  return result
}

/**
 * 對象: 品牌
 * 動作: 轉 Points 給用戶
 * 備註：帶使用者的 Token 於 header 中
 */
export const PayPointToMember = async (inputData: {
  member_id: string
  point: number
  value: number
  comment: string
}) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: PayPointToMemberInputData) {
            payPointToMember(inputData: $inputData) {
              status
              message
              errors
            }
          } 
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.payPointToMember))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of PayPointToMember', err)
  }
  return result
}

/**
 * 對象：會員
 * 動作：更新實名認證的資料
 * 備註：帶使用者的 Token 於 header 中
 */
export const UpdateMemberVerified = async ({
  is_draft,
  is_foreigner,
  verified_name,
  verified_phone,
  verified_id_number,
  verified_bank_code,
  verified_bank_id,
  verified_bank_name,
  verified_address,
  verified_comment,
  verified_images,
}: {
  is_draft: boolean
  is_foreigner: boolean
  verified_name: string
  verified_phone: string
  verified_id_number: string
  verified_bank_code: string
  verified_bank_id: string
  verified_bank_name: string
  verified_address: string
  verified_comment: string
  verified_images: Array<string>
}) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: UpdateMemberVerifiedInputData) {
            updateMemberVerified(inputData: $inputData) {
              payload {
                verified_name
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData: {
              is_draft,
              is_foreigner,
              verified_name,
              verified_phone,
              verified_id_number,
              verified_bank_code,
              verified_bank_id,
              verified_bank_name,
              verified_address,
              verified_comment,
              verified_images,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateMemberVerified))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateMemberVerified', err)
  }
  return result
}
/**
 * 對象：會員
 * 動作：更新實名認證的資料
 * 備註：帶使用者的 Token 於 header 中
 */
export const UpdateBusinessVerified = async (inputData: {
  verified_company_name: string
  verified_tax_id: string
  verified_address: string
  verified_pricipal_name: string
  verified_pricipal_phone: string
  verified_pricipal_email: string
  verified_bank_id: string
  verified_bank_code: string
  verified_comment: string
  verified_images: Array<string>
}) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: UpdateBusinessVerifiedInputData) {
            updateBusinessVerified(inputData: $inputData) {
              payload {
                verified_company_name
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateBusinessVerified))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateBusinessVerified', err)
  }
  return result
}

/**
 * 對象：會員
 * 動作：完成每日案件，發一元
 * 備註：只會在 LINE 上的 Liff 使用
 */
export const ConfirmMissionCompleted = async () => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation {
            confirmMissionCompleted {
              status
              message
            }
          }
        `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.confirmMissionCompleted))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of ConfirmMissionCompleted', err)
  }
  return result
}

/**
 * 對象：商家
 * 動作：透過虛擬帳戶支付勞務報酬款項
 */
export const CreateTransferGroup = async (
  inputData: {
    title: string
    items: Array<{
      member_id: string
      points: number
      description: string
    }>
    business?: {
      email: string
      name: string
      tax_id: string
    }
  },
  withHeader?: boolean
) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: CreateTransferGroupInputData) {
            createTransferGroup(inputData: $inputData) {
              payload {
                virtual_bank_account
                payment_amount
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        withHeader
          ? {
              headers: {
                'x-authorization': ReadAccountToken(),
              },
            }
          : {}
      )
      .then((r) => (result = r.data.data.createTransferGroup))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateTransferGroup', err)
  }
  return result
}

/**
 * 對象：商家
 * 動作：透過虛擬帳戶支付勞務報酬款項
 */
export const CreateTransferGroupBiz = async (
  inputData: {
    title: string
    items: Array<{
      member_id: string
      points: number
      description: string
    }>
    business?: {
      email: string
      name: string
      tax_id: string
    }
  },
  withHeader?: boolean
) => {
  let result
  try {
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData: CreateTransferGroupInputData) {
            createTransferGroup(inputData: $inputData) {
              payload {
                virtual_bank_account
                payment_amount
              }
              status
              message
              errors
            }
          }
          `,
          variables: {
            inputData,
          },
        },
        withHeader
          ? {
              headers: {
                'x-authorization': ReadAccountToken('biz'),
              },
            }
          : {}
      )
      .then((r) => (result = r.data.data.createTransferGroup))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateTransferGroup', err)
  }
  return result
}

/**
 * 對象：商家
 * 動作：查看商家所有虛擬帳號的紀錄
 */
export const GetTransferGroups = async () => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
            query {
              getTransferGroups {
                payload {
                  id
                  title
                  total_points
                  virtual_bank_account
                  is_pay
                  created_at
                  tax_payment
                  items {
                    member {
                      first_name
                      last_name
                      email
                      picture
                    }
                    description
                    points
                    is_transfer
                  }
                }
                status
                message
                errors
              }
            }
          `,
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.getTransferGroups))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetTransferGroups', err)
  }
  return result
}

export const UpdateMemberPaymentRequestArchived = async (inputData: {
  payment_request_id: string
  archived: boolean
}) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: UpdateMemberPaymentRequestArchivedInputData!) {
              updateMemberPaymentRequestArchived(inputData: $inputData) {
                message
                status
                errors
              }
            }
          `,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const { data } = result.data
    if (data.updateMemberPaymentRequestArchived) {
      const message = data.updateMemberPaymentRequestArchived.message
      if (message === 'Success') {
        const status = data.updateMemberPaymentRequestArchived.status
        const payload = data.updateMemberPaymentRequestArchived.payload
        return {
          status,
          payload,
          message,
          error: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of UpdateMemberPaymentRequestArchived',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：會員建立請款單
 */
interface CreateMemberPaymentRequestParams {
  value: number
  tax_amt: number
  description: string
  labor_project_code: string
  labor_project_domain: string
  discount_coupon_id: string
  picture_9b: string
}
export const CreateMemberPaymentRequest = async ({
  value,
  tax_amt,
  description,
  labor_project_code,
  labor_project_domain,
  discount_coupon_id,
  picture_9b,
}: CreateMemberPaymentRequestParams) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: CreateMemberPaymentRequestInputData) {
              createMemberPaymentRequest(inputData: $inputData) {
                payload {
                  payment_request_id
                  access_code
                }
                status
                message
                errors
              }
            }
          `,
        variables: {
          inputData: {
            value,
            tax_amt,
            description,
            labor_project_code,
            labor_project_domain,
            discount_coupon_id,
            picture_9b,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const createMemberPaymentRequestResult =
      result.data.data.createMemberPaymentRequest
    if (!createMemberPaymentRequestResult) {
      const errors = result.data.errors
      if (errors && errors.length >= 1) {
        const err = errors[0]
        throw err.message
      }
    }
    if (createMemberPaymentRequestResult.status >= 400) {
      throw createMemberPaymentRequestResult.message
    }
    return {
      payload: createMemberPaymentRequestResult,
      error: null,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of CreateMemberPaymentRequest',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：查詢個人所有請款單
 */
interface GetMemberPaymentRequestsInputData {
  limit?: number
  offset?: number
  status?: number
  orderBy?: {
    field: string
    sort: string
  }
  keyword?: string
}
export const GetMemberPaymentRequests = async (
  inputData?: GetMemberPaymentRequestsInputData
) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
            query($limit: Int, $offset: Int, $status: Int,$orderBy:OrderBy, $keyword: String) {
              getMemberPaymentRequests(limit: $limit, offset: $offset, status: $status,orderBy:$orderBy,keyword: $keyword) {
                payload {
                  comment
                  mode
                  id
                  virtual_account
                  access_code
                  description
                  value
                  status
                  labor_project_code
                  tax_amt
                  labor_project_domain
                  company_name
                  company_tax_id
                  c_name
                  c_phone
                  notification_email
                  withdraw_pool {
                    origin_value
                    value
                    parent_type
                  }
                  discount_coupon {
                    charge_fee
                  }
                  member_bill {
                    fee
                  }
                  member{
                    verified_name
                  }
                  created_at
                  updated_at
                  project_file{
                    s_signature
                    c_signature
                    project {
                      id
                      title
                      comment
                      c_name
                      status
                    }
                  }
                }
                pagination {
                  limit offset count
                }
                errors
                message
                status
              }
            }
          `,
        variables: {
          limit: inputData?.limit || 3,
          offset: inputData?.offset || 0,
          status: inputData?.status,
          orderBy: inputData?.orderBy,
          keyword: inputData?.keyword,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const memberPaymentRequestsResult =
      result.data.data.getMemberPaymentRequests
    if (!memberPaymentRequestsResult) {
      const errors = result.data.errors
      if (errors && errors.length >= 1) {
        const err = errors[0]
        throw err.message
      }
    }
    if (memberPaymentRequestsResult.status >= 400) {
      throw memberPaymentRequestsResult.message
    }
    return {
      payload: memberPaymentRequestsResult,
      paginatio: memberPaymentRequestsResult.pagination,
      error: null,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of GetMemberPaymentRequests',
      error: err,
    }
  }
}

export const GetMemberWalletLogs = async (
  limit?: number,
  offset?: number,
  status?: number | null,
  action?: number | null,
  orderBy?: {
    field: string
    sort: string
  }
) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(
        API_URL,
        {
          query: `
          query($limit:Int,$offset:Int,$status:Int,$action:Int,$orderBy:OrderBy){
            getMemberWalletLogs(limit:$limit,offset:$offset,status:$status,action:$action,orderBy:$orderBy) {
            payload {
               user_id
               action   
               id
               body
               status
               receive_user_id
               request_entity_id               
               user_type
               created_at
               updated_at
               labor_reports              
            }
            pagination {
              count
              limit
              offset
            }  
            message
            errors
            status
          }
        }
      `,
          variables: {
            limit,
            offset,
            status,
            action,
            orderBy,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )

      .then((r) => (result = r.data.data.getMemberWalletLogs))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMemberWalletLogs', err)
  }
  return result
}

/**
 * 對象：會員、業主
 * 動作：查詢請款單
 */
interface GetMemberPaymentRequestParams {
  [key: string]: string | string[]
}
export const GetMemberPaymentRequest = async ({
  id,
  access_code,
}: GetMemberPaymentRequestParams) => {
  try {
    CheckAPIURL()
    const result = await axios.post(API_URL, {
      query: `
            query($inputData: GetMemberPaymentRequestInputData) {
              getMemberPaymentRequest(inputData: $inputData) {
                payload {
                  mode
                  id
                  virtual_account
                  access_code
                  description
                  invoice_info
                  value
                  tax_amt
                  status
                  company_tax_id
                  company_name
                  labor_project_code
                  labor_project_domain
                  picture_9b
                  created_at
                  updated_at
                  comment
                  c_name
                  c_phone
                  notification_email
                  member {
                    verified_name
                    email
                  }
                  discount_coupon{
                    charge_fee
                    expired_at
                    code
                  }
                  project_file{
                    c_signature
                    s_signature
                    s_signatured_at
                    c_signatured_at
                    project{
                      id
                      title
                      comment
                      c_name
                      c_phone
                      status
                    }
                  }
                  member_bill{
                    fee
                  }
                }
                errors
                message
                status
              }
            }
          `,
      variables: {
        inputData: {
          id,
          access_code,
        },
      },
    })
    const memberPaymentRequestResult = result.data.data.getMemberPaymentRequest
    if (!memberPaymentRequestResult) {
      const errors = result.data.errors
      if (errors && errors.length >= 1) {
        const err = errors[0]
        throw err.message
      }
    }
    if (memberPaymentRequestResult.status >= 400) {
      throw memberPaymentRequestResult.message
    }
    return {
      payload: memberPaymentRequestResult.payload,
      error: null,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of GetMemberPaymentRequest',
      error: err,
    }
  }
}

/**
 * 對象：會員、業主
 * 動作：建立請款單虛擬帳號
 */
interface CreateMemberPaymentRequestVirtualAccountParams {
  [key: string]: string | string[]
}
export const CreateMemberPaymentRequestVirtualAccount = async ({
  id,
  access_code,
  notification_email,
  company_name,
  company_tax_id,
}: CreateMemberPaymentRequestVirtualAccountParams) => {
  try {
    CheckAPIURL()
    const result = await axios.post(API_URL, {
      query: `
            mutation($inputData: CreateMemberPaymentRequestVirtualAccountInputData) {
              createMemberPaymentRequestVirtualAccount(inputData: $inputData) {
                payload {
                  virtual_account
                }
                errors
                message
                status
              }
            }
          `,
      variables: {
        inputData: {
          id,
          access_code,
          notification_email,
          company_name,
          company_tax_id,
        },
      },
    })
    const createMemberPaymentRequestVirtualAccountResult =
      result.data.data.createMemberPaymentRequestVirtualAccount
    if (!createMemberPaymentRequestVirtualAccountResult) {
      const errors = result.data.errors
      if (errors && errors.length >= 1) {
        const err = errors[0]
        throw err.message
      }
    }
    if (createMemberPaymentRequestVirtualAccountResult.status >= 400) {
      throw createMemberPaymentRequestVirtualAccountResult.message
    }
    return {
      payload: createMemberPaymentRequestVirtualAccountResult.payload,
      error: null,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of CreateMemberPaymentRequestVirtualAccount',
      error: err,
    }
  }
}

/**
 * 對象：企業端
 * 動作：建立專案
 */
interface CreateTransferProjectParams {
  title: string
  description: string
  items: string[]
}
export const CreateTransferProject = async ({
  title,
  description,
  items,
}: CreateTransferProjectParams) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: CreateTransferProjectInputData) {
              createTransferProject(inputData: $inputData) {
                payload {
                  id
                }
                message
                status 
                errors
              }
            }
          `,
        variables: {
          inputData: {
            title,
            description,
            items,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.createTransferProject) {
      const message = data.createTransferProject.message
      if (message === 'Success') {
        const status = data.createTransferProject.status
        const payload = data.createTransferProject.payload
        return {
          status,
          payload,
          errors: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of CreateTransferProject',
      errors: err,
    }
  }
}
/**
 * 對象：企業端
 * 動作：查看所有專案
 */
interface GetTransferProjectsParams {
  limit: number
  offset: number
  keyword?: string
  orderBy?: {
    field: string
    sort: 'asc' | 'desc'
    nulls?: 'first' | 'last'
  }
  status?: number
}
export const GetTransferProjects = async ({
  offset = 0,
  limit = 10,
  keyword,
  orderBy,
  status,
}: GetTransferProjectsParams) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            query($offset: Int, $limit: Int, $keyword: String, $orderBy: OrderBy, $status: Int) {
              getTransferProjects(offset: $offset, limit: $limit, keyword: $keyword, orderBy:$orderBy, status:$status) {
                payload {
                  id
                  title
                  description
                  is_cancel
                  created_at
                  updated_at
                  is_pay
                  pay_at
                  virtual_bank_account
                  invoice_info
                  items {
                    member{
                      id
                      email
                      verified_name
                    }
                    id
                    description
                    points
                    is_transfer
                    labor_project_code
                    labor_project_domain
                  }
                }
                pagination {
                  count
                  limit
                  offset
                }
                message
                status 
                errors
              }
            }
          `,
        variables: {
          offset,
          limit,
          keyword,
          orderBy,
          status,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.getTransferProjects) {
      const message = data.getTransferProjects.message
      if (message === 'Success') {
        const status = data.getTransferProjects.status
        const payload = data.getTransferProjects.payload
        const pagination = data.getTransferProjects.pagination
        return {
          status,
          pagination,
          payload,
          errors: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of GetTransferProjects',
      errors: err,
    }
  }
}
/**
 * 對象：企業端
 * 動作：查看單一專案
 */
interface GetTransferProjectParams {
  project_id: string
}
export const GetTransferProject = async ({
  project_id,
}: GetTransferProjectParams) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            query($inputData: GetTransferProjectInputData) {
              getTransferProject(inputData: $inputData) {
                payload {
                  title
                  description
                  virtual_bank_account
                  is_cancel
                  is_pay 
                  created_at 
                  updated_at
                  pay_at
                    invoice_info
                    can_modify 
                    items {
                    member {
                      id
                      email
                      verified_name
                      verified_state
                      nick_name
                    }
                    id
                    description
                    points
                    is_transfer
                    labor_project_code
                    labor_project_domain
                  }
                }
                message
                status
                errors
              }
              
            }
          `,
        variables: {
          inputData: {
            project_id: project_id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.getTransferProject) {
      const message = data.getTransferProject.message
      if (message === 'Success') {
        const status = data.getTransferProject.status
        const payload = data.getTransferProject.payload
        return {
          status,
          payload,
          errors: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of GetTransferProject',
      errors: err,
    }
  }
}

/**
 * 對象：企業端
 * 動作：查看所有合作夥伴
 */

interface GetBusinessPartnerMembersInputData {
  transfer_project_id?: string
  verified_state: string
}

interface GetBusinessPartnersInputData {
  with_report?: boolean
  offset: number
  limit?: number
  filter?: string
  inputData?: GetBusinessPartnerMembersInputData
  orderBy?: {
    field: string
    sort: string
  }
}
export const GetBusinessPartners = async ({
  with_report,
  offset,
  limit,
  filter,
  inputData,
  orderBy,
}: GetBusinessPartnersInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            query($offset: Int, $limit: Int, $filter: String, $inputData: GetBusinessPartnerMembersInputData, $orderBy: OrderBy ) {
              getBusinessPartnerMembers(offset: $offset, limit: $limit, filter: $filter, inputData: $inputData, orderBy:$orderBy) {
                payload {
                  partner {
                    id
                    verified_name
                    verified_state
                    verified_reject_reson
                    is_confirm_wallet
                    email
                    nick_name
                    report(with_report: ${with_report}) {
                      total_case
                      total_amount
                      case_items {
                        project_id
                        project_title
                        project_is_pay
                        points
                        description
                        created_at
                        labor_project_code
                      }
                    }
                  }
                  created_at
                }
                pagination {
                  limit offset count
                }
                status
                message
                errors
              }
            }
          `,
        variables: {
          offset,
          limit,
          filter,
          inputData,
          orderBy,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.getBusinessPartnerMembers) {
      const message = data.getBusinessPartnerMembers.message
      if (message === 'Success') {
        const status = data.getBusinessPartnerMembers.status
        const payload = data.getBusinessPartnerMembers.payload
        const count = data.getBusinessPartnerMembers.pagination.count
        const limit = data.getBusinessPartnerMembers.pagination.limit
        return {
          status,
          payload,
          error: null,
          count,
          limit,
          offset,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of GetBusinessPartnerMembers',
      error: err,
    }
  }
}

export const GetBusinessPartnersForExport = async () => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            query($exports: Boolean) {
              getBusinessPartnerMembers(exports: $exports) {
                payload {
                  partner {
                    id
                    verified_name
                    email
                  }
                  created_at
                }
                status
                message
                errors
              }
            }
          `,
        variables: {
          exports: true,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.getBusinessPartnerMembers) {
      const message = data.getBusinessPartnerMembers.message
      if (message === 'Success') {
        const status = data.getBusinessPartnerMembers.status
        const payload = data.getBusinessPartnerMembers.payload
        return {
          status,
          payload,
          error: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of GetBusinessPartnerMembers',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：加入合作夥伴
 */
export const ConfirmBusinessPartnerInvite = async (business_id: string) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: ConfirmBusinessPartnerInviteInputData) {
              confirmBusinessPartnerInvite(inputData: $inputData) {
                status
                message
              }
            } 
          `,
        variables: {
          inputData: {
            business_id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const { data } = result.data
    if (data.confirmBusinessPartnerInvite) {
      const message = data.confirmBusinessPartnerInvite.message
      if (message === 'Success') {
        const status = data.confirmBusinessPartnerInvite.status
        const payload = data.confirmBusinessPartnerInvite.payload
        return {
          status,
          payload,
          message,
          error: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of ConfirmBusinessPartnerInvite',
      error: err,
    }
  }
}

/**
 * 對象：企業
 * 動作：專案產生虛擬帳號
 */
interface CreateProjectVirtualBankAccountItem {
  email: string
  points: number
  member_id: string
  description: string
  labor_project_code: string
  labor_project_domain: string
}
interface CreateProjectVirtualBankAccountInputData {
  transfer_project_id: string
  items: CreateProjectVirtualBankAccountItem[]
}
export const CreateProjectVirtualBankAccount = async ({
  items,
  transfer_project_id,
}: CreateProjectVirtualBankAccountInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: CreateProjectVirtualBankAccountInputData) {
              createProjectVirtualBankAccount(inputData: $inputData) {
                payload {
                  virtual_bank_account
                  payment_amount
                }
                status
                message
                errors
              }
            } 
          `,
        variables: {
          inputData: {
            transfer_project_id,
            items,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.createProjectVirtualBankAccount) {
      const message = data.createProjectVirtualBankAccount.message
      if (message === 'Success') {
        const status = data.createProjectVirtualBankAccount.status
        const payload = data.createProjectVirtualBankAccount.payload
        return {
          status,
          payload,
          message,
          errors: null,
        }
      } else {
        return {
          origin: 'Error of createProjectVirtualBankAccount',
          errors: data.createProjectVirtualBankAccount.errors,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of createProjectVirtualBankAccount',
      errors: err,
    }
  }
}

/**
 * 對象：企業
 * 動作：儲存更新專案項目
 */
interface UpdateTransferProjectItem {
  points: number
  member_id: string
  description: string
}
interface UpdateTransferProjectItemsInputData {
  transfer_project_id: string
  items: UpdateTransferProjectItem[]
}
export const UpdateTransferProjectItems = async ({
  items,
  transfer_project_id,
}: UpdateTransferProjectItemsInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: UpdateTransferProjectItemsInputData) {
              updateTransferProjectItems(inputData: $inputData) {
                status
                message
                errors
              }
            } 
          `,
        variables: {
          inputData: {
            transfer_project_id,
            items,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.updateTransferProjectItems) {
      const message = data.updateTransferProjectItems.message
      if (message === 'Success') {
        const status = data.updateTransferProjectItems.status
        const payload = data.updateTransferProjectItems.payload
        return {
          status,
          payload,
          message,
          error: null,
        }
      } else {
        return {
          origin: 'Error of updateTransferProjectItems',
          error: data.updateTransferProjectItems.errors,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of updateTransferProjectItems',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：建立發包專案
 */
interface CreateSubcontractorProjectInputData {
  title: string
  description: string
}
export const createSubcontractorProject = async ({
  title,
  description,
}: CreateSubcontractorProjectInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: CreateSubcontractorProjectInputData) {
          createSubcontractorProject(inputData: $inputData) {
            payload {
              id
            }
            message
            status
            errors
          }
        }`,
        variables: {
          inputData: {
            title,
            description,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const data = result.data.data
    const subcontractorProject = data.createSubcontractorProject
    const errors = subcontractorProject.errors
    if (errors) {
      alert(errors)
    }
    const { id } = subcontractorProject.payload
    return {
      id,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of createSubcontractorProject',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：建立發包專案
 */
interface GetSubcontractorProjectsInputData {
  offset?: number
  limit?: number
  keyword?: string
}
export const getSubcontractorProjects = async ({
  offset = 0,
  limit = 3,
  keyword = '',
}: GetSubcontractorProjectsInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
        query($limit: Int, $offset: Int, $keyword: String) {
          getSubcontractorProjects(limit: $limit, offset: $offset, keyword: $keyword) {
            payload {
              id
              title
              description
              total_points
              is_pay
              access_code
              created_at
              updated_at
            }
            pagination {
              count,
              limit,
              offset
            }
            message
            status
            errors
          }
        }`,
        variables: {
          offset,
          limit,
          keyword,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const data = result.data.data
    const subcontractorProjects = data.getSubcontractorProjects
    const errors = subcontractorProjects.errors
    if (errors) {
      alert(errors)
    }
    return {
      payload: subcontractorProjects.payload,
      paginationInfo: subcontractorProjects.pagination,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of createSubcontractorProject',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：查看特定發包專案
 */
interface GetSubcontractorProjectInputData {
  id: string | string[]
}
export const getSubcontractorProject = async ({
  id,
}: GetSubcontractorProjectInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
        query($inputData: GetSubcontractorProjectInputData) {
          getSubcontractorProject(inputData: $inputData) {
            payload {
              id
              title
              description
              total_points
              is_pay
              access_code
              items {
                member_id
                member {
                  id
                  email
                  verified_name
                }
                is_transfer
                description
                points
              }
              created_at
              updated_at
            }
            message
            status
            errors
          }
        }`,
        variables: {
          inputData: {
            id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const data = result.data.data
    const subcontractorProject = data.getSubcontractorProject
    const errors = subcontractorProject.errors
    if (errors) {
      alert(errors)
    }
    return {
      payload: subcontractorProject.payload,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of getSubcontractorProject',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：查詢已驗證會員
 */
interface GetVerifiedMemberInputData {
  email: string
}
export const getVerifiedMember = async ({
  email,
}: GetVerifiedMemberInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
        query($inputData: GetVerifiedMemberInputData) {
          getVerifiedMember(inputData: $inputData) {
            payload {
              id
              verified_name
            }
            message
            status
            errors
          }
        }`,
        variables: {
          inputData: {
            email,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const data = result.data.data
    const verifiedMember = data.getVerifiedMember
    const errors = verifiedMember.errors
    if (errors) {
      alert(errors)
    }
    return {
      payload: verifiedMember.payload,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of getVerifiedMember',
      error: err,
    }
  }
}

/**
 * 對象：會員
 * 動作：更新專案內容
 */
interface UpdateSubcontractorProjectInputData {
  subcontractor_id: string
  owner: {
    points: number
    description: string
  }
  contractors: {
    member_id: string
    description: string
    points: number
  }[]
}
export const updateSubcontractorProject = async ({
  subcontractor_id,
  owner,
  contractors,
}: UpdateSubcontractorProjectInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: UpdateSubcontractorProjectInputData) {
          updateSubcontractorProject(inputData: $inputData) {
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            subcontractor_id,
            owner,
            contractors,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const data = result.data.data
    const updateSubcontractorProject = data.updateSubcontractorProject
    const message = updateSubcontractorProject.message
    const errors = updateSubcontractorProject.errors
    return {
      message,
      errors,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of updateSubcontractorProject',
      error: err,
    }
  }
}

interface GetMemberWithdrawPoolsInputData {
  limit?: number
  offset?: number
  keyword?: string
}
export const getMemberWithdrawPools = async ({
  limit = 0,
  offset = 10,
  keyword = 'FULL',
}: GetMemberWithdrawPoolsInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken()
    const result = await axios.post(
      API_URL,
      {
        query: `
        query($limit: Int, $offset: Int, $keyword: String) {
          getMemberWithdrawPools(limit: $limit, offset: $offset, keyword: $keyword) {
            payload {
              member_withdraw_pools {
                origin_value
                value
                parent_id
                parent_type
                labor_project_code
                labor_project_domain
                status
              }
            }
            pagination {
              count
              limit
              offset
            }
            message
            errors
            status
          }
        }`,
        variables: {
          limit,
          offset,
          keyword,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    const data = result.data.data
    const memberWithdrawPools = data.getMemberWithdrawPools
    const errors = memberWithdrawPools.errors
    if (errors) {
      alert(errors)
    }
    return {
      payload: memberWithdrawPools.payload,
      paginationInfo: memberWithdrawPools.pagination,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of getMemberWithdrawPools',
      error: err,
    }
  }
}

/**
 * 查看包商請款單
 */
interface GetSubcontractorProjectPaymentRequestInputData {
  access_code: string | string[]
  subcontractor_project_id: string | string[]
}
export const getSubcontractorProjectPaymentRequest = async ({
  access_code,
  subcontractor_project_id,
}: GetSubcontractorProjectPaymentRequestInputData) => {
  try {
    CheckAPIURL()
    const result = await axios.post(API_URL, {
      query: `
      query($inputData: GetSubcontractorProjectPaymentRequestInputData) {
        getSubcontractorProjectPaymentRequest(inputData: $inputData) {
          message
          status
          payload {
            subcontractor_id title description virtual_bank_account virtual_bank_account_created_at
            is_pay invoice_info pay_points created_at updated_at total_points
            member {
              verified_name
              email
            }
            labors {
              labor_project_code
              labor_project_domain
            }
          }
          errors
        }
      }
    `,
      variables: {
        inputData: {
          subcontractor_project_id,
          access_code,
        },
      },
    })

    const data = result.data.data
    const subcontractorProjectPaymentRequest =
      data.getSubcontractorProjectPaymentRequest
    return {
      payload: subcontractorProjectPaymentRequest.payload,
      error: subcontractorProjectPaymentRequest.errors,
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      payload: null,
      origin: 'Error of getSubcontractorProjectPaymentRequest',
      error: err,
    }
  }
}

/**
 * 對象: 任一網站訪客
 * 動作: 查看重設密碼token是否過期
 */

interface getResetPasswordTokenExpiredInputData {
  token: string
}
export const getResetPasswordTokenExpired = async ({
  token,
}: getResetPasswordTokenExpiredInputData) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          query($inputData: GetResetPasswordTokenExpiredInputData) {
            getResetPasswordTokenExpired(inputData: $inputData) {
              payload {
                is_expired
              }
              message
              status
              errors
            }
          }
      `,
        variables: {
          inputData: {
            token: token,
          },
        },
      })
      .then((r) => (result = r.data.data.getResetPasswordTokenExpired))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getResetPasswordTokenExpired', err)
  }
  return result
}

/**
 * 對象: 會員
 * SOHO 查看合作企業
 */

interface GetMemberPartnerBizsInputData {
  limit?: number
  offset?: number
  keyword?: string
}

export const GetMemberPartnerBizs = async (
  inputData?: GetMemberPartnerBizsInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
        query($limit: Int, $offset: Int,$keyword: String){
          getMemberPartnerBizs(limit: $limit, offset: $offset, keyword:$keyword){
           payload {
            verified_company_name
            total_amt_received
          }	
          pagination {
            offset
            limit
            count
          }
            status
            message
            errors
          }
        }`,
          variables: {
            limit: inputData?.limit || 8,
            offset: inputData?.offset || 0,
            keyword: inputData?.keyword,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.getMemberPartnerBizs))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetMemberPartnerBizs', err)
  }
  return result
}

/**
 * 對象: 企業 端
 * 動作: 用email或名字搜尋用戶
 */

interface GetMemberFromBizInputData {
  email?: string
  name?: string
}

export const getMemberFromBiz = async ({
  email,
  name,
}: GetMemberFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const res = await axios.post(
      API_URL,
      {
        query: `
        query($inputData: GetMemberFromBizInputData){
          getMemberFromBiz(inputData: $inputData){
           payload {
            members
              {
              id
              email
              verified_name
              verified_state
            }
          }	
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            email,
            name,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )

    result = res.data.data.getMemberFromBiz.payload
    const message = res.data.data.getMemberFromBiz.message
    const errors = res.data.data.getMemberFromBiz.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getMemberFromBiz', err)
  }
  return result
}

/**
 * 對象: 企業端
 * 動作: 移除合作夥伴
 */

interface RemovePartnerFromBizInputData {
  id: string
}
export const removePartnerFromBiz = async ({
  id,
}: RemovePartnerFromBizInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: RemovePartnerFromBizInputData) {
              removePartnerFromBiz(inputData: $inputData) {
                message
                status 
                errors
              }
            }
          `,
        variables: {
          inputData: {
            id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.removePartnerFromBiz) {
      const message = data.removePartnerFromBiz.message
      if (message === 'Success') {
        const status = data.removePartnerFromBiz.status
        return {
          status,
          error: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      origin: 'Error of removePartnerFromBiz',
      error: err,
    }
  }
}

/**
 * 對象：企業端
 * 動作：移除合作夥伴
 */

interface InvitePartnerFromBizInputData {
  ids?: Array<string>
  email_list?: Array<string>
}
export const invitePartnerFromBiz = async ({
  ids,
  email_list,
}: InvitePartnerFromBizInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: InvitePartnerFromBizInputData) {
              invitePartnerFromBiz(inputData: $inputData) {
                message
                status 
                errors
              }
            }
          `,
        variables: {
          inputData: {
            ids,
            email_list,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.invitePartnerFromBiz) {
      const message = data.invitePartnerFromBiz.message
      if (message === 'Success') {
        const status = data.invitePartnerFromBiz.status
        return {
          status,
          error: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      origin: 'Error of invitePartnerFromBiz',
      error: err,
    }
  }
}

/**
 * 對象：企業端
 * 動作：提醒合作夥伴實名認證
 */

interface NotifyMemberFromBizInputData {
  action: string
  member_id: string
}
export const notifyMemberFromBiz = async ({
  action,
  member_id,
}: NotifyMemberFromBizInputData) => {
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const result = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: NotifyMemberFromBizInputData) {
              notifyMemberFromBiz(inputData: $inputData) {
                message
                status 
                errors
              }
            }
          `,
        variables: {
          inputData: {
            action,
            member_id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    const { data } = result.data
    if (data.notifyMemberFromBiz) {
      const message = data.notifyMemberFromBiz.message
      if (message === 'Success') {
        const status = data.notifyMemberFromBiz.status
        return {
          status,
          error: null,
        }
      }
    }
  } catch (err) {
    captureExceptionFromError(err)
    return {
      origin: 'Error of notifyMemberFromBiz',
      error: err,
    }
  }
}

/**
 * 對象：企業端
 * 動作：更新匯款群組基本操作
 */

interface UpdateProjectFromBizInputData {
  id: string
  title?: string
  description?: string
  can_modify?: boolean
  is_archive?: boolean
}

export const UpdateProjectFromBiz = async (
  inputData: UpdateProjectFromBizInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($id: String!, $title: String ,$description: String, $can_modify: Boolean, $is_archive: Boolean){
          updateProjectFromBiz(inputData:{id: $id, title: $title, description:$description, can_modify:$can_modify, is_archive:$is_archive}){         
            status
            message
            errors
          }
        }`,
          variables: {
            id: inputData.id,
            title: inputData.title,
            description: inputData.description,
            can_modify: inputData.can_modify,
            is_archive: inputData.is_archive,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateProjectFromBiz))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateProjectFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：匯款群組添加新的合作夥伴
 */

interface AddPartnerToExistingTransferProjectInputData {
  transfer_project_id: string
  items: string[]
}
export const AddPartnerToExistingTransferProject = async ({
  transfer_project_id,
  items,
}: AddPartnerToExistingTransferProjectInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: AddPartnerToExistingTransferProjectInputData){
          addPartnerToExistingTransferProject(inputData: $inputData){         
            status
            message
            errors
          }
        }`,
          variables: {
            inputData: {
              transfer_project_id,
              items,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.addPartnerToExistingTransferProject))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of AddPartnerToExistingTransferProject', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：匯款群組子項目更新
 */

interface UpdateTransferProjectItemFromBizInputData {
  transfer_project_id: string
  member_id: string
  labor_project_code: string
  labor_project_domain: string
  description: string
  points: number
}
export const UpdateTransferProjectItemFromBiz = async ({
  transfer_project_id,
  member_id,
  labor_project_code,
  labor_project_domain,
  description,
  points,
}: UpdateTransferProjectItemFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: UpdateTransferProjectItemFromBizInputData){
          updateTransferProjectItemFromBiz(inputData: $inputData){         
            status
            message
            errors
          }
        }`,
          variables: {
            inputData: {
              transfer_project_id,
              member_id,
              labor_project_code,
              labor_project_domain,
              description,
              points,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateTransferProjectItemFromBiz))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateTransferProjectItemFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：移除匯款群組子項目
 */

interface DeleteTransferProjectItemFromBizInputData {
  transfer_project_id: string
  transfer_project_item_id: string
}
export const DeleteTransferProjectItemFromBiz = async ({
  transfer_project_id,
  transfer_project_item_id,
}: DeleteTransferProjectItemFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
        mutation($inputData: DeleteTransferProjectItemFromBizInputData){
          deleteTransferProjectItemFromBiz(inputData: $inputData){         
            status
            message
            errors
          }
        }`,
          variables: {
            inputData: {
              transfer_project_id,
              transfer_project_item_id,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.deleteTransferProjectItemFromBiz))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteTransferProjectItemFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：購買發放額度
 */
interface CreateBuyQuotaOrderFromBizInputData {
  quota_amount: number
  signature: string
  pdf_url: string
}

export const CreateBuyQuotaOrderFromBiz = async ({
  quota_amount,
  signature,
  pdf_url,
}: CreateBuyQuotaOrderFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const res: any = await axios.post(
      API_URL,
      {
        query: `
          mutation($inputData: CreateBuyQuotaOrderFromBizInputData){
            createBuyQuotaOrderFromBiz(inputData: $inputData){
              payload {
                quota
                quota_total_price
                quota_allow_swap_points
                virtual_bank_account
              }      
              status
              message
              errors
            }
          }`,
        variables: {
          inputData: {
            quota_amount,
            signature,
            pdf_url,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    result = res.data.data.createBuyQuotaOrderFromBiz
    const message = res.data.data.createBuyQuotaOrderFromBiz.message
    const errors = res.data.data.createBuyQuotaOrderFromBiz.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteTransferProjectItemFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：移除購買額度訂單
 */

interface DeleteBuyQuotaOrderFromBizInputData {
  id: String
}
export const DeleteBuyQuotaOrderFromBiz = async ({
  id,
}: DeleteBuyQuotaOrderFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const res: any = await axios.post(
      API_URL,
      {
        query: `
          mutation($inputData: DeleteBuyQuotaOrderFromBizInputData){
            deleteBuyQuotaOrderFromBiz(inputData: $inputData){         
              status
              message
              errors
            }
          }`,
        variables: {
          inputData: {
            id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    result = res.data.data.deleteBuyQuotaOrderFromBiz
    const message = res.data.data.deleteBuyQuotaOrderFromBiz.message
    const errors = res.data.data.deleteBuyQuotaOrderFromBiz.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of DeleteTransferProjectItemFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：企業查詢所有購買額度訂單
 */

export const GetBuyQuotaOrdersFromBiz = async ({ offset = 0, limit = 10 }) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const res = await axios.post(
      API_URL,
      {
        query: `
          query($offset: Int, $limit: Int) {
            getBuyQuotaOrdersFromBiz(offset: $offset, limit: $limit) {
              payload {
                id
                quota
                quota_type
                quota_total_price
                quota_allow_swap_points
                remaining_swap_points
                buy_contract_id
                started_at
                expired_at
                is_pay
                invoice_info
                other
                created_at
              }
              pagination {
                 limit
                 offset
                 count
              }
              status
              message
              errors
            }
          }
          `,
        variables: {
          offset,
          limit,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    result = res.data.data.getBuyQuotaOrdersFromBiz
    const message = res.data.data.getBuyQuotaOrdersFromBiz.message
    const errors = res.data.data.getBuyQuotaOrdersFromBiz.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetBuyQuotaOrdersFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：企業查詢單一購買額度訂單詳情
 */

interface GetBuyQuotaOrderFromBizInputData {
  id: String
}

export const GetBuyQuotaOrderFromBiz = async ({
  id,
}: GetBuyQuotaOrderFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const res = await axios.post(
      API_URL,
      {
        query: `
          query($inputData: GetBuyQuotaOrderFromBizInputData) {
            getBuyQuotaOrderFromBiz(inputData: $inputData) {
              payload {
                id
                quota
                quota_total_price
                quota_allow_swap_points
                virtual_bank_account
                is_pay
              }
              status
              message
              errors
            }
          }
          `,
        variables: {
          inputData: {
            id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    result = res.data.data.getBuyQuotaOrderFromBiz
    const message = res.data.data.getBuyQuotaOrderFromBiz.message
    const errors = res.data.data.getBuyQuotaOrderFromBiz.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getBuyQuotaOrderFromBiz', err)
  }
  return result
}

/**
 * 對象：企業端
 * 動作：移除購買額度訂單
 */

interface LogoutTransferProjectVirtualBankAccountFromBizInputData {
  id: String
}
export const LogoutTransferProjectVirtualBankAccountFromBiz = async ({
  id,
}: LogoutTransferProjectVirtualBankAccountFromBizInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    const res: any = await axios.post(
      API_URL,
      {
        query: `
          mutation($inputData: LogoutTransferProjectVirtualBankAccountFromBizInputData){
            logoutTransferProjectVirtualBankAccountFromBiz(inputData: $inputData){
              status
              message
              errors
            }
          }`,
        variables: {
          inputData: {
            id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken('biz'),
        },
      }
    )
    console.log(res)
    result = res.data.data.logoutTransferProjectVirtualBankAccountFromBiz
    const message =
      res.data.data.logoutTransferProjectVirtualBankAccountFromBiz.message
    const errors =
      res.data.data.logoutTransferProjectVirtualBankAccountFromBiz.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of LogoutTransferProjectVirtualBankAccountFromBiz', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：查看匯款群組
 */

interface GetTransferProjectsFromSOHOInputData {
  offset?: number
  limit?: number
  keyword?: string
  orderBy?: {
    field: string
    sort: string
  }
  inputData?: {
    filter?: number
  }
}

export const getTransferProjectsFromSOHO = async ({
  offset,
  limit,
  keyword,
  orderBy,
  inputData,
}: GetTransferProjectsFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query($offset: Int, $limit: Int, $keyword: String, $orderBy: OrderBy, $inputData: GetTransferProjectsFromSOHOInputData){
          getTransferProjectsFromSOHO(offset:$offset, limit:$limit, keyword:$keyword, orderBy:$orderBy, inputData:$inputData){
          payload {
            id
            title
            is_transfer
            points
            labor_project_domain
            created_at
            verified_company_name
            can_modify
            description
          }	
          pagination {
            count
            limit
            offset
          }
            status
            message
            errors
          }
        }`,
        variables: {
          offset,
          limit,
          keyword,
          orderBy,
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getTransferProjectsFromSOHO
    const message = res.data.data.getTransferProjectsFromSOHO.message
    const errors = res.data.data.getTransferProjectsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getTransferProjectsFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：查看單一匯款群組
 */

interface GetTransferProjectItemFromSOHOInputData {
  transfer_project_item_id: string
}

export const getTransferProjectItemFromSOHO = async ({
  transfer_project_item_id,
}: GetTransferProjectItemFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query($inputData: GetTransferProjectItemFromSOHOInputData ){
          getTransferProjectItemFromSOHO(inputData:$inputData){
          payload {
            title
            description
            points
            labor_project_domain
            labor_project_domain_code
            can_modify
            is_transfer
            created_at
            verified_company_name
          }
            errors
            status
            message
          }
        }`,
        variables: {
          inputData: {
            transfer_project_item_id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getTransferProjectItemFromSOHO
    const message = res.data.data.getTransferProjectItemFromSOHO.message
    const errors = res.data.data.getTransferProjectItemFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getTransferProjectItemFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：更新匯款群組子項目
 */

interface UpdateTransferProjectItemFromSOHOInputData {
  transfer_project_item_id: string
  points: number
  description: string
  labor_project_code: string
  labor_project_domain: string
}

export const updateTransferProjectItemFromSOHO = async ({
  transfer_project_item_id,
  points,
  description,
  labor_project_code,
  labor_project_domain,
}: UpdateTransferProjectItemFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: UpdateTransferProjectItemFromSOHOInputData){
          updateTransferProjectItemFromSOHO(inputData: $inputData){
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            transfer_project_item_id,
            points,
            description,
            labor_project_code,
            labor_project_domain,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateTransferProjectItemFromSOHO
    const message = res.data.data.updateTransferProjectItemFromSOHO.message
    const errors = res.data.data.updateTransferProjectItemFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of updateTransferProjectItemFromSOHO', err)
  }
  return result
}

/**
 * 對象：Biz端
 * 動作：後端計算後數據
 */

export const GetBusinessAnalytics = async (year: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
          query ($year: String){
            getBusiness{
              payload {                
                business {
                  analytics(year: $year){
                    quota{
                      balance
                    }
                    transfer_projects{
                      is_not_pay_count
                      total
                    } 
                    partners{
                      total
                      news_count
                      unvalidated_count
                    }
                    charts {
                      month {
                        total_is_pay_amount
                      }
                      year{
                        total_is_not_pay_amount
                      }
                      years {
                        title_collection
                        project_points
                        total_project_points
                        pay_at                        
                      }            
                    }
                  }
                  verified_pricipal_name
                  verified_pricipal_phone
                  verified_pricipal_email
                }
              }
              message
              status
              errors
            }
          }
          `,
          variables: {
            year,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.getBusiness))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetBusinessAnalytics', err)
  }
  return result
}

/**
 * 對象：Biz端
 * 動作：Biz端取的全部匯款發票
 */

interface GetTransferProjectsInvoiceInfoFromBizInputData {
  offset?: number
  limit?: number
  orderBy?: {
    sort: string
    field: string
  }
  inputData: {
    from: string
    to: string
  }
}

export const GetTransferProjectsInvoiceInfoFromBiz = async (
  inputData?: GetTransferProjectsInvoiceInfoFromBizInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
          query ($offset: Int,$limit: Int,$orderBy:OrderBy, $inputData: GetTransferProjectsInvoiceInfoFromBizInputData!,){
            getTransferProjectsInvoiceInfoFromBiz(limit: $limit, offset: $offset,orderBy:$orderBy,inputData:$inputData){
              payload {                
                title
                invoice_info
                virtual_bank_account
                items {
                  points
                  description                  
                  labor_project_code                     member {
                    verified_name
                  }
                }           
              }
              pagination {
                count
                offset
                limit
              }
                
              message
              status
              errors
            }
          }
          `,
          variables: {
            offset: inputData?.offset,
            limit: inputData?.limit,
            orderBy: inputData?.orderBy,
            inputData: inputData?.inputData,
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.getTransferProjectsInvoiceInfoFromBiz))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetTransferProjectsInvoiceInfoFromBiz', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：請款單轉換模式
 */

interface ChangeMemberPaymentRequestModeFromSOHOInputData {
  payment_request_id: string
  mode: string
}

export const ChangeMemberPaymentRequestModeFromSOHO = async ({
  payment_request_id,
  mode,
}: ChangeMemberPaymentRequestModeFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation($inputData:ChangeMemberPaymentRequestModeFromSOHOInputData){
            changeMemberPaymentRequestModeFromSOHO(inputData: $inputData){      
              message
              status
              errors
            }
          }
          `,
          variables: {
            inputData: {
              payment_request_id,
              mode,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then(
        (r) => (result = r.data.data.changeMemberPaymentRequestModeFromSOHO)
      )
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of ChangeMemberPaymentRequestModeFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：先開發票模式, 建立發票
 */

interface CreatePaymentRequestInvoiceFromGuessInputData {
  payment_request_id: string
  access_code: string
  company_name: string
  company_tax_id: string
  notification_email: string
}

export const CreatePaymentRequestInvoiceFromGuess = async ({
  payment_request_id,
  access_code,
  company_name,
  company_tax_id,
  notification_email,
}: CreatePaymentRequestInvoiceFromGuessInputData) => {
  let result
  try {
    CheckAPIURL()
    await axios
      .post(API_URL, {
        query: `
          mutation  ($inputData:CreatePaymentRequestInvoiceFromGuessInputData){
            createPaymentRequestInvoiceFromGuess(inputData: $inputData){      
              message
              status
              errors
            }
          }
          `,
        variables: {
          inputData: {
            payment_request_id,
            access_code,
            company_name,
            company_tax_id,
            notification_email,
          },
        },
      })
      .then((r) => (result = r.data.data.createPaymentRequestInvoiceFromGuess))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreatePaymentRequestInvoiceFromGuess', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：更新 freshchat restore_id
 */

interface UpdateMemberFCRestoreIdInputData {
  restore_id: string
}

export const UpdateMemberFCRestoreId = async ({
  restore_id,
}: UpdateMemberFCRestoreIdInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation  ($inputData:UpdateMemberFCRestoreIdInputData){
            updateMemberFCRestoreId(inputData: $inputData){      
              message
              status
              errors
            }
          }
          `,
          variables: {
            inputData: {
              restore_id,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken(),
          },
        }
      )
      .then((r) => (result = r.data.data.updateMemberFCRestoreId))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateMemberFCRestoreId', err)
  }
  return result
}

/**
 * 對象：Biz端
 * 動作：更新 freshchat restore_id
 */

interface UpdateBusinessFCRestoreIdInputData {
  restore_id: string
}

export const UpdateFCRestoreIdFromBiz = async ({
  restore_id,
}: UpdateBusinessFCRestoreIdInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken('biz')
    await axios
      .post(
        API_URL,
        {
          query: `
          mutation  ($inputData:UpdateBusinessFCRestoreIdInputData){
            updateFCRestoreIdFromBiz(inputData: $inputData){      
              message
              status
              errors
            }
          }
          `,
          variables: {
            inputData: {
              restore_id,
            },
          },
        },
        {
          headers: {
            'x-authorization': ReadAccountToken('biz'),
          },
        }
      )
      .then((r) => (result = r.data.data.updateFCRestoreIdFromBiz))
      .catch((err) => {
        captureExceptionFromError(err)
        throw err
      })
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateFCRestoreIdFromBiz', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：建立專案(Project)
 */

interface CreateProjectFromSOHOInputData {
  title: string
  comment?: string
}

export const createProjectFromSOHO = async ({
  title,
  comment,
}: CreateProjectFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: CreateProjectFromSOHOInputData){
          createProjectFromSOHO(inputData: $inputData){
            payload {
              id
            }
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            title,
            comment,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.createProjectFromSOHO
    const message = res.data.data.createProjectFromSOHO.message
    const errors = res.data.data.createProjectFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：檢視所有專案(Projects)
 */

interface GetProjectsFromSOHOInputData {
  offset: number
  limit: number
  orderBy?: {
    field: string
    sort: 'asc' | 'desc'
    nulls?: 'first' | 'last'
  }
  filter?: {
    title?: String
    status?: String
  }
}

export const getProjectsFromSOHO = async ({
  offset,
  limit,
  orderBy,
  filter,
}: GetProjectsFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query($offset: Int, $limit: Int, $orderBy: OrderBy, $filter: GetProjectsFromSOHOFilter) {
          getProjectsFromSOHO(offset: $offset, limit: $limit, orderBy:$orderBy, filter:$filter) {
            payload {
              id
              title
              status
              comment            
              c_company_name         
              created_at
              started_at
              completed_at
            }
            analysis {
              totalIncomes
              totalCosts
            }
            pagination {
              count
              limit
              offset
            }
            status
            message
            errors
          }
        }`,
        variables: {
          offset,
          limit,
          orderBy,
          filter,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getProjectsFromSOHO
    const message = res.data.data.getProjectsFromSOHO.message
    const errors = res.data.data.getProjectsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectsFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：更新單一專案(Project)
 */

interface UpdateProjectFromSOHOInputData {
  project_id: String
  title?: string
  status?: string
  comment?: string
  c_company_name?: string
  is_archived?: boolean
  started_at?: string
  completed_at?: string
}

export const updateProjectFromSOHO = async ({
  project_id,
  title,
  status,
  comment,
  c_company_name,
  is_archived,
  started_at,
  completed_at,
}: UpdateProjectFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: UpdateProjectFromSOHOInputData) {
          updateProjectFromSOHO(inputData: $inputData) {
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            project_id,
            title,
            status,
            comment,
            c_company_name,
            is_archived,
            started_at,
            completed_at,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateProjectFromSOHO
    const message = res.data.data.updateProjectFromSOHO.message
    const errors = res.data.data.updateProjectFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectsFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：檢視單一專案(Project)
 */

interface GetProjectFromSOHOInputData {
  id: string
}

export const getProjectFromSOHO = async ({
  id,
}: GetProjectFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query($inputData: GetProjectFromSOHOInputData) {
          getProjectFromSOHO(inputData: $inputData) {
            payload {
              id
              title         
              status
              comment              
              c_company_name            
              created_at             
              access_code
              is_archived
              started_at
              completed_at
            }
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            id,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getProjectFromSOHO
    const message = res.data.data.getProjectFromSOHO.message
    const errors = res.data.data.getProjectFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：檢視單一專案(Project)文件
 */

const PrField = `member_payment_request{
                id
                value
                tax_amt
                description              
                virtual_account
                invoice_info
                access_code                
                status
                created_at
                updated_at
                mode
                labor_project_code
                labor_project_domain
                discount_coupon{
                  discount_event{
                    title
                  }
                  payment_amount
                  expired_at
                  code
                  charge_fee
                }        
                member_bill{
                  fee
                }       
              }`

const QtsField = `
              quotations{
                description
                amount
                tax_amount
                effective_at
                created_at
                access_code
                updated_at
              }`

interface GetProjectFilesFromSOHOInputData {
  id: string
  type?: 'QTS' | 'PR'
  is_archived: boolean
  filter?: 'ALL' | 'PAY' | 'NOTPAY' | 'SIG' | 'NOTSIG' | 'ARCHIVED'
}

export const getProjectFilesFromSOHO = async ({
  id,
  type,
  is_archived,
  filter,
}: GetProjectFilesFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query($inputData: GetProjectFilesFromSOHOInputData) {
          getProjectFilesFromSOHO(inputData: $inputData) {
            payload {
              file_type
              file_id
              s_signature
              s_signatured_at
              c_signature
              c_signatured_at
              mac
              created_at
              ${
                type === 'PR'
                  ? PrField
                  : type === 'QTS'
                  ? QtsField
                  : PrField + QtsField
              }
            }
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            id,
            is_archived,
            filter,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getProjectFilesFromSOHO
    const message = res.data.data.getProjectFilesFromSOHO.message
    const errors = res.data.data.getProjectFilesFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectFilesFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：建立文件
 */

interface CreateProjectFileFromSOHOInputData {
  id: string
  file_type: 'PR' | 'QTS'
  amount?: number
  tax_amt?: number
  discount_coupon_id?: string
  picture_9b?: string
  comment?: string
  effective_at?: string
}

export const createProjectFileFromSOHO = async ({
  id,
  file_type,
  amount,
  tax_amt,
  discount_coupon_id,
  picture_9b,
  comment,
  effective_at,
}: CreateProjectFileFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: CreateProjectFileFromSOHOInputData) {
          createProjectFileFromSOHO(inputData: $inputData) {
            payload {
              payment_request_id
              quotations_id
            }
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            id,
            file_type,
            amount,
            tax_amt,
            discount_coupon_id,
            picture_9b,
            comment,
            effective_at,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.createProjectFileFromSOHO
    const message = res.data.data.createProjectFileFromSOHO.message
    const errors = res.data.data.createProjectFileFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of createProjectFileFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：文件簽名
 */

interface SignatureFileInputData {
  signature: string
  file_id: string
  target: 'SOHO' | 'CUSTOMER'
}

export const signatureFile = async ({
  signature,
  file_id,
  target,
}: SignatureFileInputData) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
        mutation($inputData: SignatureFileInputData) {
          signatureFile(inputData: $inputData) {
            status
            message
            errors
          }
        }`,
      variables: {
        inputData: {
          signature,
          file_id,
          target,
        },
      },
    })
    result = res.data.data.signatureFile
    const message = res.data.data.signatureFile.message
    const errors = res.data.data.signatureFile.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of signatureFile', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：更新報價單
 */

interface UpdateQuotationsFromSOHOInputData {
  id: string
  access_code: string
  comment?: string
  effective_at?: string
  is_archived?: boolean
  c_company_name?: string
  c_company_tax_id?: string
  c_phone?: string
  c_email?: string
  c_name?: string
  amount?: number
  tax_amount?: number
  description?: string
}

export const updateQuotationsFromSOHO = async (
  inputData: UpdateQuotationsFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: UpdateQuotationsFromSOHOInputData) {
          updateQuotationsFromSOHO(inputData: $inputData) {
            status
            message
            errors
          }
        }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateQuotationsFromSOHO
    const message = res.data.data.updateQuotationsFromSOHO.message
    const errors = res.data.data.updateQuotationsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of updateQuotationsFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：更新請款單
 */

interface UpdatePaymentRequestFromSOHOInputData {
  id: string
  access_code: string
  value?: number
  tax_amt?: number
  comment?: string
  is_archived?: boolean
  discount_coupon_id?: string
  cancel_discount_coupon?: boolean
  c_name?: string
  c_phone?: string
  company_name?: string
  company_tax_id?: string
  notification_email?: string
  labor_project_code?: string
  labor_project_domain?: string
  description?: string
  picture_9b?: string
}

export const updatePaymentRequestFromSOHO = async ({
  id,
  access_code,
  value,
  tax_amt,
  comment,
  is_archived,
  discount_coupon_id,
  cancel_discount_coupon,
  c_name,
  c_phone,
  company_name,
  company_tax_id,
  notification_email,
  labor_project_code,
  labor_project_domain,
  description,
  picture_9b,
}: UpdatePaymentRequestFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        mutation($inputData: UpdatePaymentRequestFromSOHOInputData) {
          updatePaymentRequestFromSOHO(inputData: $inputData) {
            status
            message
            errors
          }
        }`,
        variables: {
          inputData: {
            id,
            access_code,
            value,
            tax_amt,
            comment,
            is_archived,
            discount_coupon_id,
            cancel_discount_coupon,
            c_name,
            c_phone,
            company_name,
            company_tax_id,
            notification_email,
            labor_project_code,
            labor_project_domain,
            description,
            picture_9b,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updatePaymentRequestFromSOHO
    const message = res.data.data.updatePaymentRequestFromSOHO.message
    const errors = res.data.data.updatePaymentRequestFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of updatePaymentRequestFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：客戶查看報價單
 */

interface GetQuotationsFromGuessInputData {
  id: string
  access_code: string
  queryField?: string
}

export const GetQuotationsFromGuess = async ({
  id,
  access_code,
  queryField,
}: GetQuotationsFromGuessInputData) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
        query($inputData: GetQuotationsFromGuessInputData) {
          getQuotationsFromGuess(inputData: $inputData) {
            payload {  
              ${
                queryField ||
                ` id
              access_code
              comment
              effective_at
              created_at
              updated_at
              description
              amount
              c_name
              c_phone
              c_email
              c_company_name
              c_company_tax_id
              tax_amount
              project {
                title
                description
                amount
                c_name
                c_phone
                c_email
                c_company_name
                c_company_tax_id
              }
              project_file {
                s_signature
                s_signatured_at
                c_signature
                c_signatured_at
              }
              member {
                email
                verified_name
                verified_phone
                personal_id
              }`
              }
             
            }
            status
            message
            errors
          }
        }`,
      variables: {
        inputData: {
          id,
          access_code,
        },
      },
    })
    result = res.data.data.getQuotationsFromGuess
    const message = res.data.data.getQuotationsFromGuess.message
    const errors = res.data.data.getQuotationsFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getQuotationsFromGuess', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：客戶查看請款單
 */

interface GetMemberPaymentRequestFromGuessInputData {
  id: string
  access_code: string
  queryField?: string
}

export const GetMemberPaymentRequestFromGuess = async ({
  id,
  access_code,
  queryField,
}: GetMemberPaymentRequestFromGuessInputData) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
        query($inputData: GetMemberPaymentRequestFromGuessInputData) {
          getMemberPaymentRequestFromGuess(inputData: $inputData) {
            payload {
              ${
                queryField ||
                `id
              value
              tax_amt
              description
              virtual_account
              invoice_info
              access_code
              status
              created_at
              mode
              comment
              c_name
              c_phone
              company_name
              notification_email
              company_tax_id
              project {
                c_name
                c_phone
                c_email
                c_company_name
                c_company_tax_id
              }
              project_file {
                s_signature
                s_signatured_at
                c_signature
                c_signatured_at
                mac
              }
              member {
                email
                verified_name
                verified_phone
                personal_id
              }`
              }
            }
            status
            message
            errors
          }
        }`,
      variables: {
        inputData: {
          id,
          access_code,
        },
      },
    })
    result = res.data.data.getMemberPaymentRequestFromGuess
    const message = res.data.data.getMemberPaymentRequestFromGuess.message
    const errors = res.data.data.getMemberPaymentRequestFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getMemberPaymentRequestFromGuess', err)
  }
  return result
}

/**
 * 對象：SOHO端
 * 動作：抓近期客戶資料 (10筆)
 */

export const getProjectsCustomerFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query {
          getProjectsCustomerFromSOHO {
            payload {
              c_name
              c_company_name
              c_phone
              c_email
              c_company_tax_id
            }
            status
            message
            errors
          }
        }`,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getProjectsCustomerFromSOHO
    const message = res.data.data.getProjectsCustomerFromSOHO.message
    const errors = res.data.data.getProjectsCustomerFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectsCustomerFromSOHO', err)
  }
  return result
}

/**
 * 對象：客戶 (非會員)
 * 動作：檢視專案
 */
interface GetProjectFromGuessInputData {
  id: string
  access_code: string
}
export const getProjectFromGuess = async ({
  id,
  access_code,
}: GetProjectFromGuessInputData) => {
  let result
  try {
    const res = await axios.post(API_URL, {
      query: `
        query ($inputData: GetProjectFromGuessInputData){
          getProjectFromGuess(inputData: $inputData) {
            payload {
              project {
                id
              title
              description
              status
              amount
              labor_project_code
              labor_project_domain
              c_company_name
              c_company_tax_id
              c_phone
              c_email
              c_name
              created_at
              }
              files {
                file_type
               quotations {
                 id
                 comment
                 description
                 tax_amount
                 access_code
                 created_at
                 updated_at
               }
               member_payment_request {
                 id
                 status
                 comment
                 description
                 tax_amt
                 access_code
                 created_at
                 updated_at
               }
               s_signature
               c_signature
              }
            }
            status
            message
            errors
          }
        }`,
      variables: {
        inputData: {
          id,
          access_code,
        },
      },
    })
    result = res.data.data.getProjectFromGuess
    const message = res.data.data.getProjectFromGuess.message
    const errors = res.data.data.getProjectFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectFromGuess', err)
  }
  return result
}

/**
 * 對象：會員、訪客
 * 動作：加入等待清單
 */
interface JoinTheWaitingListInputData {
  name: string
  email: string
  feature: string
}
export const JoinTheWaitingList = async (
  inputData: JoinTheWaitingListInputData
) => {
  let result
  try {
    const res = await axios.post(API_URL, {
      query: `
          mutation($inputData: JoinTheWaitingListInputData) {
            joinTheWaitingList(inputData: $inputData) {
              message
              errors
              status
            }
          }
        `,
      variables: {
        inputData: inputData,
      },
    })
    result = res.data.data.joinTheWaitingList
    const message = res.data.data.joinTheWaitingList.message
    const errors = res.data.data.joinTheWaitingList.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureException(err)
    console.log('Error of JoinTheWaitingList', err)
  }
  return result
}

/**
 * 對象：客戶（非會員）
 * 動作：查看認證的相關資料
 */
interface GetMemberAchievementsFromGuessInputData {
  member_id: string
}
export const GetMemberAchievementsFromGuess = async (
  inputData: GetMemberAchievementsFromGuessInputData
) => {
  let result
  try {
    const res = await axios.post(API_URL, {
      query: `
          query($inputData: GetMemberAchievementsFromGuessInputData!) {
            getMemberAchievementsFromGuess(inputData: $inputData) {
              payload {
                is_verify
                is_sponsor
                payment_count
              }
              status
              errors
              message
            }
          }
        `,
      variables: {
        inputData: inputData,
      },
    })
    result = res.data.data.getMemberAchievementsFromGuess
    const message = res.data.data.getMemberAchievementsFromGuess.message
    const errors = res.data.data.getMemberAchievementsFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureException(err)
    console.log('Error of GetMemberAchievementsFromGuess', err)
  }
  return result
}

/**
 * 對象：SOHO 端
 * 動作：更新問卷調查功能
 */

interface UpdateAnalyticsFormsFromSOHOInputData {
  fields: string
}
export const UpdateAnalyticsFormsFromSOHO = async ({
  fields,
}: UpdateAnalyticsFormsFromSOHOInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: UpdateAnalyticsFormsFromSOHOInputData) {
              updateAnalyticsFormsFromSOHO(inputData: $inputData) {
                message
                errors
              }
            }
        `,
        variables: {
          inputData: {
            fields,
          },
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateAnalyticsFormsFromSOHO
    const message = res.data.data.updateAnalyticsFormsFromSOHO.message
    const errors = res.data.data.updateAnalyticsFormsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of updateAnalyticsFormsFromSOHO', err)
  }
  return result
}

/**
 * 請款單移入/出專案
 * @returns
 */
interface MovePrInputData {
  id: string
  access_code: string
  project_id: string
}
export const MovePr = async ({
  id,
  access_code,
  project_id,
}: MovePrInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              updatePaymentRequestFromSOHO(inputData: {
                id: "${id}"
                access_code: "${access_code}"
                project_id: "${project_id}"
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updatePaymentRequestFromSOHO
    const message = res.data.data.updatePaymentRequestFromSOHO.message
    const errors = res.data.data.updatePaymentRequestFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of MovePr', err)
  }
  return result
}

/**
 * 報價單移入/出專案
 * @returns
 */
interface MoveQtsInputData {
  id: string
  access_code: string
  project_id: string
}
export const MoveQts = async ({
  id,
  access_code,
  project_id,
}: MoveQtsInputData) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              updateQuotationsFromSOHO(inputData: {
                id: "${id}"
                access_code: "${access_code}"
                project_id: "${project_id}"
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateQuotationsFromSOHO
    const message = res.data.data.updateQuotationsFromSOHO.message
    const errors = res.data.data.updateQuotationsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of MoveQts', err)
  }
  return result
}

/**
 * 新增專案收入明細
 * @returns
 */
export const CreateProjectIncomeFromSOHO = async ({
  project_id,
  content,
  value,
}: {
  project_id: string
  content: string
  value: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              createProjectIncomeFromSOHO(inputData: {
                project_id: "${project_id}"
                content: "${content}"
                value: ${value}
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.createProjectIncomeFromSOHO
    const message = res.data.data.createProjectIncomeFromSOHO.message
    const errors = res.data.data.createProjectIncomeFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of createProjectIncomeFromSOHO', err)
  }
  return result
}

/**
 * 移除專案收入明細
 * @returns
 */
export const DeleteProjectIncomeFromSOHO = async ({
  project_id,
  id,
}: {
  project_id: string
  id: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              deleteProjectIncomeFromSOHO(inputData: {
                project_id: "${project_id}"
                id: ${id}
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.deleteProjectIncomeFromSOHO
    const message = res.data.data.deleteProjectIncomeFromSOHO.message
    const errors = res.data.data.deleteProjectIncomeFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of deleteProjectIncomeFromSOHO', err)
  }
  return result
}

/**
 * 更新專案收入明細
 * @returns
 */
export const UpdateProjectIncomeFromSOHO = async ({
  project_id,
  id,
  content,
  value,
}: {
  project_id: string
  id: number
  content?: string
  value?: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              updateProjectIncomeFromSOHO(inputData: {
                project_id: "${project_id}"
                id: ${id}
                content: "${content}"
                value: ${value}
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateProjectIncomeFromSOHO
    const message = res.data.data.updateProjectIncomeFromSOHO.message
    const errors = res.data.data.updateProjectIncomeFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of updateProjectIncomeFromSOHO', err)
  }
  return result
}

/**
 * 新增專案支出明細
 */
export const CreateProjectCostFromSOHO = async ({
  project_id,
  content,
  value,
}: {
  project_id: string
  content: string
  value: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              createProjectCostFromSOHO(inputData: {
                project_id: "${project_id}"
                content: "${content}"
                value: ${value}
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.createProjectCostFromSOHO
    const message = res.data.data.createProjectCostFromSOHO.message
    const errors = res.data.data.createProjectCostFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of createProjectCostFromSOHO', err)
  }
  return result
}

/**
 * 移除專案支出明細
 */
export const DeleteProjectCostFromSOHO = async ({
  project_id,
  id,
}: {
  project_id: string
  id: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              deleteProjectCostFromSOHO(inputData: {
                project_id: "${project_id}"
                id: ${id}
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.deleteProjectCostFromSOHO
    const message = res.data.data.deleteProjectCostFromSOHO.message
    const errors = res.data.data.deleteProjectCostFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of deleteProjectCostFromSOHO', err)
  }
  return result
}

/**
 * 更新專案收入明細
 * @returns
 */
export const UpdateProjectCostFromSOHO = async ({
  project_id,
  id,
  content,
  value,
}: {
  project_id: string
  id: number
  content?: string
  value?: number
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation {
              updateProjectCostFromSOHO(inputData: {
                project_id: "${project_id}"
                id: ${id}
                content: "${content}"
                value: ${value}
              }) {
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateProjectCostFromSOHO
    const message = res.data.data.updateProjectCostFromSOHO.message
    const errors = res.data.data.updateProjectCostFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of updateProjectCostFromSOHO', err)
  }
  return result
}

/**
 * 取得專案的收入明細
 */
export const GetProjectIncomesAndCostsFromSOHO = async ({
  project_id,
}: {
  project_id: string
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            query {
              getProjectIncomesAndCostsFromSOHO(inputData: {
                project_id: "${project_id}"
              }) {
                payload {
                   costs {
                      id
                      content
                      value
                    }
                    incomes {
                      id
                      content
                      value
                    }
                    extraCosts {
                      id
                      content
                      value
                    }
                    extraIncomes {
                      id
                      content
                      value
                    }
                }
                message
                errors
              }
            }
        `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getProjectIncomesAndCostsFromSOHO
    const message = res.data.data.getProjectIncomesAndCostsFromSOHO.message
    const errors = res.data.data.getProjectIncomesAndCostsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of getProjectIncomesAndCostsFromSOHO', err)
  }
  return result
}

/**
 * 提供 Guess（客戶）新增所得稅試算結果
 */
export const CreateTaxCalculationLogFromGuess = async (inputData: {
  user_id: string
  calculation_data: string
}) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
            mutation($inputData: CreateTaxCalculationLogFromGuessInputData) {
              createTaxCalculationLogFromGuess(inputData: $inputData) {
                message
              }
            }
        `,
      variables: {
        inputData,
      },
    })
    result = res.data.data.createTaxCalculationLogFromGuess
    const message = res.data.data.createTaxCalculationLogFromGuess.message
    const errors = res.data.data.createTaxCalculationLogFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CreateTaxCalculationLogFromGuessInputData', err)
  }
  return result
}

// Group 社團功能
/**
 * 查詢所有塗鴉牆社群案件
 */
export const GetCaseGroupItemsFromSOHO = async () => {
  let result
  const token = ReadAccountToken()
  try {
    CheckAPIURL()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query {
          getCaseGroupItemsFromSOHO {
            payload {
              id
              title
              templates {
                block1
                block2
                block3
                block4
                block5
              }
              cgir {
                id
                role_name
                quantity_required
                cgirm {
                  member_id
                  member {
                    id
                    verified_name
                    nick_name
                    picture
                  }
                }
              }
              case_group_item_candidates {
                created_at
                member_id
              }
              case_group {
                id
                title
                avatar
                created_at
              }
              viewer
              is_completed
              is_publish
              created_at
            }
            message
            status
            errors
          }
        }
      `,
      },
      {
        headers: token
          ? {
              'x-authorization': token,
            }
          : {},
      }
    )
    result = res.data.data.getCaseGroupItemsFromSOHO
    const message = res.data.data.getCaseGroupItemsFromSOHO.message
    const errors = res.data.data.getCaseGroupItemsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetCaseGroupItemsFromSOHO', err)
  }
  return result
}

/**
 * 查詢已加入社群
 */
export const GetJoinedCaseGroupsFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query {
          getJoinedCaseGroupsFromSOHO {
            payload {
              case_group_id
              title
              avatar
            }
            status
            message
            errors
          }
        }
      `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getJoinedCaseGroupsFromSOHO
    const message = res.data.data.getJoinedCaseGroupsFromSOHO.message
    const errors = res.data.data.getJoinedCaseGroupsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetJoinedCaseGroupsFromSOHO', err)
  }
  return result
}

/**
 * 查詢所有案件社群
 */
export const GetCaseGroupsFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
        query {
          getCaseGroupsFromSOHO {
            payload {
              id
              title
              description
              short_id
              avatar
              case_group_members {
                member {
                  id
                  personal_id
                  nick_name
                  email
                  picture
                }
              }
              templates {
                question1
              }
              created_at
            }
            status
            message
            errors
          }
        }
      `,
    })
    result = res.data.data.getCaseGroupsFromSOHO
    const message = res.data.data.getCaseGroupsFromSOHO.message
    const errors = res.data.data.getCaseGroupsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetCaseGroupsFromSOHO', err)
  }
  return result
}

/**
 * 查詢指定社群
 */
export const GetCaseGroupFromSOHO = async ({
  case_group_id,
}: {
  case_group_id: string
}) => {
  let result
  const token = WindowExist() ? ReadAccountToken() : null
  try {
    CheckAPIURL()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query {
          getCaseGroupFromSOHO(inputData: {
            case_group_id: "${case_group_id}"
          }) {
            payload {
              id
              title
              description
              short_id
              avatar
              case_group_members {
                member {
                  id
                  picture
                  bio
                  verified_state
                  description
                  cover_picture
                  nick_name
                  email
                  personal_id
                }
              }
              templates {
                question1
              }
              created_at
            }
            status
            message
            errors
          }
        }
      `,
      },
      {
        headers: token
          ? {
              'x-authorization': token,
            }
          : {},
      }
    )
    result = res.data.data.getCaseGroupFromSOHO
    const message = res.data.data.getCaseGroupFromSOHO.message
    const errors = res.data.data.getCaseGroupFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetCaseGroupFromSOHO', err)
  }
  return result
}

/**
 * 加入社團
 */
export const JoinCaseGroupFromSOHO = async (inputData: { id: string }) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: JoinCaseGroupFromSOHOInputData) {
              joinCaseGroupFromSOHO(inputData: $inputData) {
                status
                message
                errors
              }
            }
        `,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.joinCaseGroupFromSOHO
    const message = res.data.data.joinCaseGroupFromSOHO.message
    const errors = res.data.data.joinCaseGroupFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of JoinCaseGroupFromSOHO', err)
  }
  return result
}

/**
 * 退出社團
 */
export const LeaveCaseGroupFromSOHO = async (inputData: {
  case_group_id: string
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: LeaveCaseGroupFromSOHOInputData) {
              leaveCaseGroupFromSOHO(inputData: $inputData) {
                status
                message
                errors
              }
            }
        `,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.leaveCaseGroupFromSOHO
    const message = res.data.data.leaveCaseGroupFromSOHO.message
    const errors = res.data.data.leaveCaseGroupFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of LeaveCaseGroupFromSOHO', err)
  }
  return result
}

/**
 * 立即報名案件
 */
export const ApplyCaseGroupItemCandidateFromSOHO = async (inputData: {
  case_group_item_id: string
  case_group_item_role_id: string
  comment?: string
}) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: ApplyCaseGroupItemCandidateFromSOHOInputData) {
              applyCaseGroupItemCandidateFromSOHO(inputData: $inputData) {
                status
                message
                errors
              }
            }
        `,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.applyCaseGroupItemCandidateFromSOHO
    const message = res.data.data.applyCaseGroupItemCandidateFromSOHO.message
    const errors = res.data.data.applyCaseGroupItemCandidateFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of ApplyCaseGroupItemCandidateFromSOHO', err)
  }
  return result
}

interface CollectCaseGroupItemViewerFromGuessInputData {
  case_group_item_id: string
}
/**
 * 添加任務瀏覽人次
 */
export const CollectCaseGroupItemViewerFromGuess = async (
  inputData: CollectCaseGroupItemViewerFromGuessInputData
) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
            mutation($inputData: CollectCaseGroupItemViewerFromGuessInputData) {
              collectCaseGroupItemViewerFromGuess(inputData: $inputData) {
                status
                message
                errors
              }
            }
        `,
      variables: {
        inputData,
      },
    })
    result = res.data.data.collectCaseGroupItemViewerFromGuess
    const message = res.data.data.collectCaseGroupItemViewerFromGuess.message
    const errors = res.data.data.collectCaseGroupItemViewerFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of CollectCaseGroupItemViewerFromGuess', err)
  }
  return result
}

/**
 * 查看所有提示登記
 */
export const GetSOHOTipsFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
        query {
          getSOHOTipsFromSOHO {
            payload {
              tip_id
            }
            status
            message
            errors
          }
        }
      `,
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getSOHOTipsFromSOHO
    const message = res.data.data.getSOHOTipsFromSOHO.message
    const errors = res.data.data.getSOHOTipsFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetSOHOTipsFromSOHO', err)
  }
  return result
}

interface UpdateSOHOTipFromSOHOInputData {
  tip_id: String
}
/**
 * 將指定提示登記為不再顯示
 */
export const UpdateSOHOTipFromSOHO = async (
  inputData: UpdateSOHOTipFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
            mutation($inputData: UpdateSOHOTipFromSOHOInputData) {
              updateSOHOTipFromSOHO(inputData: $inputData) {
                status
                message
                errors
              }
            }
        `,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateSOHOTipFromSOHO
    const message = res.data.data.updateSOHOTipFromSOHO.message
    const errors = res.data.data.updateSOHOTipFromSOHO.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of UpdateSOHOTipFromSOHO', err)
  }
  return result
}

/**
 * 查詢統計資料（第四版首頁使用）
 */
export const GetStatisticsFromGuess = async () => {
  let result
  try {
    const res = await axios.post(API_URL, {
      query: `
        query {
          getStatisticsFromGuess {
            payload {
              total_income
              total_members
              total_enterprises
            }
            status
            message
            errors
          }
        }
      `,
    })
    result = res.data.data.getStatisticsFromGuess
    const message = res.data.data.getStatisticsFromGuess.message
    const errors = res.data.data.getStatisticsFromGuess.errors
    if (message !== 'Success') {
      throw errors
    }
  } catch (err) {
    captureExceptionFromError(err)
    console.log('Error of GetStatisticsFromGuess', err)
  }
  return result
}
